import {Image as I} from 'react-native';
export const Image: any = I;
Image.clearMemoryCache = async () => {
  console.log('NOT NEEDED ON WEB');
};
Image.resizeMode = {
  contain: 'contain',
  cover: 'cover',
  stretch: 'stretch',
  center: 'center',
};
Image.priority = {
  low: 'low',
  normal: 'normal',
  high: 'high',
};
Image.cacheControl = {
  immutable: 'immutable',
  web: 'web',
  cacheOnly: 'cacheOnly',
};
Image.preload = async () => null;
Image.clearMemoryCache = async () => null;
Image.clearDiskCache = async () => null;
export default Image;
export type {FastImageProps} from './Image';
