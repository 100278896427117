import {useColors, useIsRTL, ColorsType} from '../hooks';
import {View as DefaultView} from 'react-native';
import {forwardRef} from 'react';

export type ViewProps = {
  bg?: ColorsType;
} & DefaultView['props'];

export const View = forwardRef<DefaultView, ViewProps>((props, ref) => {
  const {style, bg, ...otherProps} = props;
  const Colors = useColors();
  const backgroundColor = bg && bg in Colors ? Colors[bg] : Colors.bg;
  const isRTL = useIsRTL();
  const dir = isRTL ? 'rtl' : 'ltr';

  return <DefaultView ref={ref} dir={dir} style={[{backgroundColor}, style]} {...otherProps} />;
});
