import {typographyStyles} from '../consts';
import {useColors, ColorsType} from '../hooks';
import {Text as DefaultText, TextProps as DTextProps, Platform} from 'react-native';

export interface TextProps extends DTextProps {
  variant?: keyof typeof typographyStyles;
  color?: ColorsType;
  selectable?: boolean;
}

export function Text({
  style,
  color = 'color',
  variant,
  selectable = false,
  ...extraProps
}: TextProps) {
  const C = useColors();
  const tcolor = C[color] || C.color;
  const variation = variant && typographyStyles[variant];
  return (
    <DefaultText
      style={[{color: tcolor}, variation, Platform.OS !== 'web' && {textAlign: 'left'}, style]}
      allowFontScaling={false}
      selectable={selectable}
      {...extraProps}
    />
  );
}
