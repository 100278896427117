import {IconProps} from '../types';
import {Circle, Path, Svg} from 'react-native-svg';

export const Plus = ({size = 24, color = '#82828E'}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      d="M3 12C3 7.02944 7.02944 3 12 3H21V12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
      stroke={color}
      strokeWidth="2"
    />
    <Path
      d="M12 8V16"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16 12L8 12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const PlusSelected = ({size = 24}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      d="M3 12C3 7.02944 7.02944 3 12 3H21V12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
      fill="#E60AF0"
      stroke="#E60AF0"
      strokeWidth="2"
    />
    <Path
      d="M12 8V16"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16 12L8 12"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const Home = ({size = 24, color = '#82828E'}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      d="M3 10L4.34211 8.89474M21 10L19.5 8.89474M19.5 8.89474L12.7547 3.92453C12.017 3.38096 11.0043 3.40826 10.2969 3.99078L4.34211 8.89474M19.5 8.89474V19C19.5 20.1046 18.6046 21 17.5 21H6.47408C5.37969 21 4.48853 20.1204 4.47425 19.0261L4.34211 8.89474"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </Svg>
);

export const HomeSelected = ({size = 24}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      d="M19.5 8.89476L12.7547 3.92456C12.017 3.38098 11.0043 3.40828 10.2969 3.99081L4.34211 8.89476L4.47425 19.0261C4.48853 20.1204 5.37969 21 6.47408 21H17.5C18.6046 21 19.5 20.1046 19.5 19V8.89476Z"
      fill="#E60AF0"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66123 3.21888C10.7223 2.34509 12.2414 2.30414 13.3479 3.1195L21.5932 9.19497C22.0378 9.52259 22.1327 10.1486 21.8051 10.5932C21.4998 11.0076 20.9353 11.1182 20.5 10.8662V19C20.5 20.6569 19.1569 22 17.5 22H6.47411C4.83252 22 3.49578 20.6806 3.47437 19.0392L3.36859 10.9299C2.97576 11.0857 2.51119 10.9795 2.2281 10.6357C1.87701 10.2094 1.938 9.57919 2.36432 9.2281L9.66123 3.21888Z"
      fill="#E60AF0"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 17C8 16.4477 8.44772 16 9 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H9C8.44772 18 8 17.5523 8 17Z"
      fill="white"
    />
  </Svg>
);

export const Profile = ({size = 24, color = '#82828E'}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 20.5C2 16.634 5.13401 13.5 9 13.5H15C18.866 13.5 22 16.634 22 20.5V21C22 21.5523 21.5523 22 21 22C20.4477 22 20 21.5523 20 21V20.5C20 17.7386 17.7614 15.5 15 15.5H9C6.23858 15.5 4 17.7386 4 20.5V21C4 21.5523 3.55228 22 3 22C2.44772 22 2 21.5523 2 21V20.5Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10Z"
      fill={color}
    />
  </Svg>
);

export const ProfileSelected = ({size = 24}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      d="M21 20.5V21H3V20.5C3 17.1863 5.68629 14.5 9 14.5H15C18.3137 14.5 21 17.1863 21 20.5Z"
      fill="#E60AF0"
      stroke="#E60AF0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 19C8 18.4477 8.44772 18 9 18H15C15.5523 18 16 18.4477 16 19C16 19.5523 15.5523 20 15 20H9C8.44772 20 8 19.5523 8 19Z"
      fill="white"
    />
    <Circle cx="12" cy="7" r="4" fill="#E60AF0" stroke="#E60AF0" strokeWidth="2" />
  </Svg>
);

export const Watchlist = ({size = 24, color = '#82828E'}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3119 3.17306C10.9364 1.60898 13.0636 1.60898 13.6881 3.17306L15.5312 7.78881L20.3152 8.18831C21.9363 8.32368 22.5936 10.4309 21.3585 11.533L17.7136 14.7851L18.8272 19.6478C19.2045 21.2955 17.4836 22.5979 16.0958 21.7149L12 19.1091L7.90424 21.7149C6.51636 22.5979 4.79546 21.2955 5.1728 19.6478L6.28637 14.7851L2.64148 11.533C1.40638 10.4309 2.06372 8.32368 3.6848 8.18831L8.46879 7.78881L10.3119 3.17306ZM12.1857 4.36957C12.1187 4.2018 11.8813 4.2018 11.8143 4.36957L10.1569 8.52017C9.89362 9.17954 9.2983 9.63007 8.61489 9.68714L4.29464 10.0479C4.11961 10.0625 4.04708 10.2795 4.17813 10.3964L7.4758 13.3388C7.99648 13.8034 8.22388 14.5324 8.0648 15.227L7.05998 19.6147C7.02007 19.789 7.21144 19.9241 7.36229 19.8281L11.047 17.4839C11.6321 17.1116 12.3679 17.1116 12.953 17.4839L16.6377 19.8281C16.7886 19.9241 16.9799 19.789 16.94 19.6147L15.9352 15.227C15.7761 14.5324 16.0035 13.8034 16.5242 13.3388L19.8219 10.3964C19.9529 10.2795 19.8804 10.0625 19.7054 10.0479L15.3851 9.68714C14.7017 9.63007 14.1064 9.17954 13.8431 8.52017L12.1857 4.36957Z"
      fill={color}
    />
  </Svg>
);

export const WatchlistSelected = ({size = 24}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      d="M20.5074 11.7864C21.4732 10.8412 20.9387 9.19736 19.6006 9.00318L15.3257 8.548C15.3115 8.54533 15.2748 8.51868 15.2558 8.48003L13.4459 3.95713C13.1506 3.35545 12.6207 3.02782 12.0354 3C11.3779 3.02778 10.8475 3.35573 10.5771 3.90459L8.72069 8.53179C8.72487 8.51822 8.68757 8.5453 8.64384 8.5517L4.42662 8.99959C3.05928 9.19747 2.52491 10.8432 3.49317 11.7882L6.59427 14.8497C6.62837 14.883 6.64275 14.9274 6.63523 14.9716L5.92969 19.0877C5.70076 20.4214 7.10079 21.4388 8.2983 20.8086C10.7645 19.512 12.0004 18.8643 12.0059 18.8656L15.7007 20.8087C16.8986 21.4384 18.2975 20.4219 18.07 19.0883L17.3634 14.9705C17.356 14.9276 17.3703 14.8837 17.402 14.8529L20.5074 11.7864Z"
      fill="#E60AF0"
      stroke="#E60AF0"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <Path
      d="M13 14L11 14"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const Dollar = ({size = 24, color = '#82828E'}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V3.93033C9.80209 4.07809 8.77104 4.49186 7.99073 5.11264C6.99819 5.90225 6.41602 7.02377 6.41602 8.34058C6.41602 9.46866 6.76119 10.4036 7.51133 11.1412C8.25767 11.8751 9.39424 12.4031 10.9577 12.744L11 12.7532V18.0603C9.61565 17.8254 8.60346 17.1071 8.24101 15.88C8.16145 15.6074 8.03831 15.3924 7.8594 15.2462C7.67908 15.0988 7.45429 15.0311 7.19129 15.0311C6.88752 15.0311 6.63041 15.1393 6.45047 15.3412C6.2719 15.5415 6.17969 15.8222 6.17969 16.1502C6.17969 16.3901 6.22522 16.6382 6.29217 16.8614L6.2925 16.8625C6.82542 18.5796 8.56372 19.8169 11 20.0762V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V20.0715C14.2995 19.9276 15.3939 19.5053 16.211 18.8444C17.2382 18.0135 17.8171 16.813 17.8171 15.3445C17.8171 14.2226 17.4885 13.3184 16.7363 12.6067C15.9894 11.9 14.8364 11.3942 13.2111 11.0378L13 10.9916V5.95596C13.3919 6.04136 13.7471 6.17923 14.0597 6.37214C14.6303 6.72432 15.0675 7.26493 15.3278 8.02497C15.4184 8.28566 15.5474 8.49648 15.7291 8.64123C15.9124 8.78728 16.1389 8.85804 16.4081 8.85804C16.6929 8.85804 16.9446 8.76269 17.1256 8.58633C17.307 8.4096 17.4089 8.15986 17.4089 7.86792C17.4089 7.65625 17.3868 7.48416 17.3511 7.35327C16.8491 5.47154 15.1597 4.22726 13 3.93578V3ZM11 5.93393C10.4478 6.03583 9.96355 6.22838 9.58076 6.49834C8.99421 6.912 8.64332 7.50813 8.64332 8.25464C8.64332 8.83564 8.83759 9.31764 9.28129 9.7133C9.65628 10.0477 10.215 10.3253 11 10.5411V5.93393ZM13 13.1966V18.0696C13.6375 17.9592 14.1873 17.7343 14.6135 17.4253C15.2319 16.977 15.5898 16.353 15.5898 15.6345C15.5898 15.0151 15.3869 14.5089 14.9312 14.0975C14.5184 13.7248 13.8912 13.4237 13 13.1966Z"
      fill={color}
    />
  </Svg>
);
