import {useColors, useStyle, ColorsType} from '../hooks';
import {SZ, colorNames} from '../consts';
import {Pressable} from '../Pressable';
import {Text} from '../Text';
import {View} from '../View';
import {Icon, IconName} from '../Icon';
import {
  GestureResponderEvent,
  StyleProp,
  ViewStyle,
  PressableProps,
  ActivityIndicator,
} from 'react-native';
import {useCallback, useMemo, useState} from 'react';

const BUTTON_STYLES: {
  [key: string]: {
    bg: ColorsType;
    color: ColorsType;
    pressedColor: ColorsType;
    borderColor: ColorsType;
    pressedBg?: ColorsType;
  };
} = {
  primary: {
    bg: colorNames.primary,
    color: colorNames.white,
    pressedColor: colorNames.primary_pressed,
    borderColor: colorNames.primary,
  },
  primary_outlined: {
    bg: colorNames.transparent,
    color: colorNames.color,
    pressedColor: colorNames.secondary_50,
    borderColor: colorNames.primary,
  },
  base: {
    bg: colorNames.color,
    color: colorNames.bg,
    pressedColor: colorNames.secondary_50,
    borderColor: colorNames.color,
  },
  outlined: {
    bg: colorNames.transparent,
    color: colorNames.color,
    pressedColor: colorNames.secondary_50,
    borderColor: colorNames.color,
  },
  sell: {
    bg: colorNames.red,
    pressedBg: colorNames.red_75,
    color: colorNames.white,
    pressedColor: colorNames.white,
    borderColor: colorNames.red,
  },
  sell_outlined: {
    bg: colorNames.transparent,
    color: colorNames.color,
    pressedColor: colorNames.red,
    borderColor: colorNames.red,
  },
  buy: {
    bg: colorNames.green,
    pressedBg: colorNames.green_75,
    color: colorNames.white,
    pressedColor: colorNames.white,
    borderColor: colorNames.green,
  },
  buy_outlined: {
    bg: colorNames.transparent,
    color: colorNames.color,
    pressedColor: colorNames.green,
    borderColor: colorNames.green,
  },
};

export interface ButtonProps extends PressableProps {
  variant?:
    | 'primary'
    | 'primary_outlined'
    | 'base'
    | 'outlined'
    | 'sell'
    | 'sell_outlined'
    | 'buy'
    | 'buy_outlined';
  disabled?: boolean;
  loading?: boolean;
  left?: IconName;
  right?: IconName;
  rtlIcon?: boolean;
  title: string;
  color?: ColorsType;
  style?: StyleProp<ViewStyle>;
  onPress?: (event: GestureResponderEvent) => any;
}

export const Button = ({
  variant = 'base',
  loading = false,
  disabled = false,
  rtlIcon = false,
  onPress,
  title,
  color,
  left,
  right,
  // bg,
  style,
}: ButtonProps) => {
  const colors = useColors();
  const S = useStyle();
  const [pressed, setPressed] = useState(false);
  const [loadingState, toggle] = useState(false);

  const styles = useMemo(() => BUTTON_STYLES[variant], [variant]);

  const backgroundColor = useMemo(() => {
    if (disabled) return colorNames.secondary_10;
    if (pressed && styles.pressedBg) return styles.pressedBg;
    return styles.bg;
  }, [disabled, styles, pressed]);

  const textColor = useMemo(() => {
    if (disabled) return colorNames.secondary_25;
    if (color) return color;
    if (pressed) return styles.pressedColor;
    return styles.color;
  }, [disabled, styles, pressed, color]);

  const bColor = useMemo(() => {
    if (disabled) return colors.transparent;
    if (pressed && styles.pressedBg) return colors[styles.pressedBg];
    return colors[styles.borderColor];
  }, [disabled, styles, colors, pressed]);

  const handleOnPress = useCallback(
    async (event: GestureResponderEvent) => {
      if (onPress) {
        try {
          const mightBePromise = onPress(event);
          if (mightBePromise && mightBePromise?.then) {
            toggle(true);
            await mightBePromise;
            toggle(false);
          }
        } catch (e) {
          toggle(false);
        }
      }
    },
    [onPress]
  );

  return (
    <Pressable
      onPress={handleOnPress}
      disabled={disabled}
      bg={backgroundColor}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      onHoverIn={() => setPressed(true)}
      onHoverOut={() => setPressed(false)}
      style={[
        S.row,
        S.justify,
        S.align,
        S.pv3,
        S.gap2,
        S.ph4,
        S.rad2,
        S.ph4,
        S.border,
        {
          borderColor: bColor,
        },
        style,
      ]}>
      {loadingState || loading ? (
        <ActivityIndicator color={colors[textColor]} style={S.pv05} />
      ) : (
        <>
          <View bg="transparent">
            {left && <Icon name={left} color={textColor} rtl={rtlIcon} />}
          </View>
          <View bg="transparent" style={{paddingVertical: SZ.x05}}>
            <Text variant="Button" color={textColor}>
              {title}
            </Text>
          </View>
          <View bg="transparent">
            {right && <Icon name={right} color={textColor} rtl={rtlIcon} />}
          </View>
        </>
      )}
    </Pressable>
  );
};
