import {useColors, ColorsType} from '../hooks';
import {ScrollView as DefaultScrollView, ScrollViewProps as DScrollViewProps} from 'react-native';
import {forwardRef} from 'react';

export type ScrollViewProps = {
  bg?: ColorsType;
} & DScrollViewProps;

export const ScrollView = forwardRef<DScrollViewProps, ScrollViewProps>(function (props, ref) {
  const {style, ...otherProps} = props;
  const Colors = useColors();
  const backgroundColor = props.bg && props.bg in Colors ? Colors[props.bg] : Colors.bg;

  // @ts-ignore
  return <DefaultScrollView ref={ref} style={[{backgroundColor}, style]} {...otherProps} />;
});

// export const ScrollView = forwardRef<DScrollViewProps, ScrollViewProps>(function (props, ref) {
//   const {style, contentContainerStyle, showShade = true, containerStyle, ...otherProps} = props;
//   const colors = useColors();
//   // const S = useStyle();
//   const backgroundColor = props.bg && props.bg in colors ? colors[props.bg] : colors.bg;

//   const [showShadow, setShowShadow] = useState(false);

//   return (
//     <View style={containerStyle}>
//       {showShade && !props.horizontal && (
//         <View
//           bg="transparent"
//           style={[
//             showShadow && {
//               height: 1,
//               zIndex: 2,
//               shadowColor: '#000',
//               shadowOffset: {
//                 width: 0,
//                 height: 4,
//               },
//               shadowOpacity: 0.8,
//               shadowRadius: 6,
//               elevation: 8,
//             },
//           ]}
//         />
//       )}
//       <DefaultScrollView
//         // @ts-ignore
//         ref={ref}
//         onScroll={e => {
//           if (!props.horizontal && showShade) {
//             const {x, y} = e.nativeEvent.contentOffset;
//             if ((x > 0 || y > 0) && !showShadow) {
//               setShowShadow(true);
//             }
//             if (x === 0 && y === 0 && showShadow) {
//               setShowShadow(false);
//             }
//           }

//           props.onScroll && props.onScroll(e);
//         }}
//         style={[{backgroundColor}, style]}
//         contentContainerStyle={contentContainerStyle}
//         {...otherProps}
//       />
//     </View>
//   );
// });
