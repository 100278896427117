import {IconProps} from '../types';
import Svg, {Circle, Defs, LinearGradient, Path, Stop} from 'react-native-svg';

/*
    this file contains icons that are not part of our design system but we still use 'em :P
*/

export const Information = (props: IconProps) => (
  <Svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill={props.color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10C12.5523 10 13 10.4477 13 11L13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17L11 11C11 10.4477 11.4477 10 12 10Z"
      fill={props.color}
    />
    <Circle cx="12" cy="7" r="1" fill={props.color} />
  </Svg>
);

export const Play = (props: IconProps) => (
  <Svg width={props.size} height={props.size} viewBox="0 0 48 48" fill="none">
    <Circle opacity={0.6} cx={24} cy={24} r={24} fill={props.color} />
    <Path
      d="M19 33.2V14L33.4 23.6L19 33.2Z"
      fill={props.accent}
      stroke={props.accent}
      strokeWidth={4.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const Smile = ({size = 24}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 25 25" fill="none">
    <Circle opacity={0.25} cx={12.5} cy={12.9375} r={12} fill="url(#paint0_linear_10187_90350)" />
    <Circle cx={9} cy={10.4375} r={1.5} fill="#662D91" />
    <Path d="M17.5 14.938a5 5 0 11-10 0h10z" fill="#662D91" />
    <Circle cx={16} cy={10.4375} r={1.5} fill="#662D91" />
    <Defs>
      <LinearGradient
        id="paint0_linear_10187_90350"
        x1={1.55549}
        y1={17.8662}
        x2={23.44}
        y2={8.00432}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#662D91" />
        <Stop offset={1} stopColor="#F0F" />
      </LinearGradient>
    </Defs>
  </Svg>
);
