import {Platform, useWindowDimensions} from 'react-native';

const screenSizes = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
} as const;

export function useMediaQuery() {
  const L = useWindowDimensions();
  const sm = L.width > screenSizes.sm;
  const md = L.width > screenSizes.md;
  const lg = L.width > screenSizes.lg;
  const xl = L.width > screenSizes.xl;
  const isMobileWeb = !md && Platform.OS === 'web';
  const isDesktop = md && Platform.OS === 'web';
  const isMobile = Platform.OS !== 'web';
  const isWeb = Platform.OS === 'web';
  return {sm, md, lg, xl, isMobileWeb, isDesktop, isMobile, isWeb};
}
