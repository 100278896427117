import Svg, {Path} from 'react-native-svg';

export const CorporateEvent = () => (
  <Svg width={40} height={18} viewBox="0 0 40 18" fill="none">
    <Path d="M0 9a9 9 0 019-9h22a9 9 0 110 18H9a9 9 0 01-9-9z" fill="#0083E6" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.434 4.434A.8.8 0 0119 4.2h1.5a.8.8 0 01.8.8v.05h-3.1V5a.8.8 0 01.234-.566zM16.8 5.05V5A2.2 2.2 0 0119 2.8h1.5A2.2 2.2 0 0122.7 5v.05H25a2.2 2.2 0 012.2 2.2v6a2.2 2.2 0 01-2.2 2.2H14.5a2.2 2.2 0 01-2.2-2.2v-6a2.2 2.2 0 012.2-2.2h2.3zm.7 1.4h-3a.8.8 0 00-.8.8v6a.8.8 0 00.8.8H25a.8.8 0 00.8-.8v-6a.8.8 0 00-.8-.8h-7.5z"
      fill="#fff"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.625 9.186a.7.7 0 01.94-.312l.157.08a2.3 2.3 0 002.056 0l.158-.08a.7.7 0 01.628 1.252l-.16.08a3.7 3.7 0 01-3.309 0l-.001-.001-.158-.08a.7.7 0 01-.311-.94z"
      fill="#fff"
    />
  </Svg>
);
export const EconomicEvent = () => (
  <Svg width={40} height={18} viewBox="0 0 40 18" fill="none">
    <Path d="M0 9a9 9 0 019-9h22a9 9 0 110 18H9a9 9 0 01-9-9z" fill="#D81515" />
    <Path
      d="M22.667 6.25a.881.881 0 01-.89-.875c0-.484.398-.875.89-.875h4.444c.492 0 .889.391.889.875V9.75a.881.881 0 01-.889.875.881.881 0 01-.889-.875V7.489l-4.705 4.629a.897.897 0 01-1.256 0l-2.953-2.88-3.79 3.755a.899.899 0 01-1.258 0 .864.864 0 010-1.236l4.446-4.375a.897.897 0 011.255 0l2.928 2.88 4.075-4.037-2.297.025z"
      fill="#fff"
    />
  </Svg>
);
