import Layout from './Layout';
import {themes} from './themes';
import {Platform, StyleSheet} from 'react-native';

export const SZ = {
  x05: 2,
  x1: 4,
  x2: 8,
  x3: 12,
  x4: 16,
  x5: 20,
  x6: 24,
  x7: 28,
  x8: 32,
  x9: 36,
  x10: 40,
  x11: 44,
  x12: 48,
  x13: 52,
  x14: 56,
  x15: 60,
} as const;

export const SS = (colors: typeof themes.dark) =>
  StyleSheet.create({
    row: {flexDirection: 'row'},
    rowReverse: {flexDirection: 'row-reverse'},
    column: {flexDirection: 'column'},
    columnReverse: {flexDirection: 'column-reverse'},
    start: {justifyContent: 'flex-start'},
    end: {justifyContent: 'flex-end'},
    flexEnd: {alignItems: 'flex-end'},
    flexStart: {alignItems: 'flex-start'},
    textRight: {textAlign: Platform.OS === 'web' ? ('end' as 'right') : 'right'},
    textLeft: {textAlign: Platform.OS === 'web' ? ('start' as 'left') : 'left'},
    primaryBtn: {
      backgroundColor: colors.primary,
      paddingHorizontal: 16,
      paddingVertical: 16,
      borderRadius: 18,
    },
    primaryBg: {backgroundColor: colors.primary},
    surface1: {backgroundColor: colors.secondary_10},
    surface2: {backgroundColor: colors.secondary_25},
    surface3: {backgroundColor: colors.secondary_75},
    primaryText: {color: colors.primary},
    primary2Text: {color: colors.secondary_75},
    primaryXText: {color: colors.secondary_75},
    borderB: {
      borderWidth: StyleSheet.hairlineWidth,
      borderColor: 'transparent',
      borderBottomColor: colors.secondary_10,
    },
    borderPrimary: {borderBottomWidth: 3, borderBottomColor: colors.primary},
    borderAccent: {borderBottomWidth: 3, borderBottomColor: colors.secondary},
    borderedAccent: {borderWidth: 1, borderColor: colors.secondary},
    borderedPrimary: {borderWidth: 1, borderColor: colors.primary},
    borderedTop: {borderTopWidth: 1, borderColor: colors.secondary_10},
    bordered: {borderWidth: 1, borderColor: colors.secondary_10, borderRadius: 8},
    webBorder: {borderWidth: 1, borderColor: colors.secondary_10},
    borderNone: {borderWidth: 0},
    minicard: {
      paddingHorizontal: 8,
      paddingVertical: 16,
    },
    card: {
      padding: 32,
      paddingStart: 18,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottomColor: colors.secondary_10,
      borderBottomWidth: 1,
    },
    onBoardingText: {
      fontSize: 15,
      fontWeight: '500',
      lineHeight: 15,
    },

    lh16: {lineHeight: 16},
    backdrop: {backgroundColor: 'rgba(0, 0, 0, 0.3)'},
    input: {
      borderRadius: 8,
      paddingHorizontal: 16,
      height: 56,
      minHeight: 40,
      color: colors.color,
      backgroundColor: colors.bg,
      borderWidth: 1,
      borderColor: colors.secondary_25,
    },
    depositField: {
      height: 64,
      paddingStart: 0,
    },
    paymentCard: {
      borderRadius: 8,
      paddingVertical: 8,
      paddingHorizontal: 16,
      backgroundColor: colors.secondary_10,
      borderWidth: 1,
      borderColor: colors.secondary_25,
    },
    bgTransparent: {backgroundColor: 'transparent'},
    bg: {backgroundColor: colors.bg},
    secondary_10: {backgroundColor: colors.secondary_10},
    secondary_25: {backgroundColor: colors.secondary_25},
    secondary_75: {backgroundColor: colors.secondary_75},
    color: {color: colors.color},
    disabled: {color: colors.secondary_50},
  });

export const typographyStyles = StyleSheet.create({
  Huge: {fontSize: 48, fontWeight: '500', lineHeight: 60},
  H1: {fontSize: 36, fontWeight: '400', lineHeight: 44},
  H1B: {fontSize: 36, fontWeight: '700', lineHeight: 44},
  H2: {fontSize: 24, fontWeight: '400', lineHeight: 32},
  H2B: {fontSize: 24, fontWeight: '700', lineHeight: 32},
  H3: {fontSize: 20, fontWeight: '400', lineHeight: 24},
  H3B: {fontSize: 20, fontWeight: '700', lineHeight: 24},
  H4: {fontSize: 16, fontWeight: '600', lineHeight: 20},
  PB: {fontSize: 16, fontWeight: '600', lineHeight: 20},
  P: {fontSize: 16, fontWeight: '400', lineHeight: 20},
  SubText: {fontSize: 14, fontWeight: '400', lineHeight: 20},
  SubTextB: {fontSize: 14, fontWeight: '600', lineHeight: 20},
  Field: {fontSize: 16, fontWeight: '400', lineHeight: 20},
  Button: {fontSize: 14, fontWeight: '500', lineHeight: 20},
  Link: {fontSize: 14, fontWeight: '600', lineHeight: 20},
  Label: {fontSize: 12, fontWeight: '400', lineHeight: 16},
  LabelB: {fontSize: 12, fontWeight: '600', lineHeight: 16},
  TabText: {fontSize: 10, fontWeight: '400', lineHeight: 12},
  PreText: {fontSize: 14, fontWeight: '700', lineHeight: 20, textTransform: 'uppercase'},
  Notification: {fontSize: 14, fontWeight: '400', lineHeight: 20},
});

export const S = StyleSheet.create({
  pad0: {paddingStart: 0, paddingEnd: 0, paddingTop: 0, paddingBottom: 0},
  pad05: {padding: SZ.x05},
  pad1: {padding: SZ.x1},
  pad2: {padding: SZ.x2},
  pad3: {padding: SZ.x3},
  pad4: {padding: SZ.x4},
  pad5: {padding: SZ.x5},
  pad6: {padding: SZ.x6},
  pad7: {padding: SZ.x7},
  pad8: {padding: SZ.x8},
  pad9: {padding: SZ.x9},
  pad10: {padding: SZ.x10},
  pad11: {padding: SZ.x11},
  pad12: {padding: SZ.x12},
  pad13: {padding: SZ.x13},
  pad14: {padding: SZ.x14},
  pad15: {padding: SZ.x15},

  ph0: {paddingHorizontal: 0},
  ph05: {paddingHorizontal: SZ.x05},
  ph1: {paddingHorizontal: SZ.x1},
  ph2: {paddingHorizontal: SZ.x2},
  ph3: {paddingHorizontal: SZ.x3},
  ph4: {paddingHorizontal: SZ.x4},
  ph5: {paddingHorizontal: SZ.x5},
  ph6: {paddingHorizontal: SZ.x6},
  ph7: {paddingHorizontal: SZ.x7},
  ph8: {paddingHorizontal: SZ.x8},
  ph9: {paddingHorizontal: SZ.x9},
  ph10: {paddingHorizontal: SZ.x10},
  ph11: {paddingHorizontal: SZ.x11},
  ph12: {paddingHorizontal: SZ.x12},
  ph13: {paddingHorizontal: SZ.x13},
  ph14: {paddingHorizontal: SZ.x14},
  ph15: {paddingHorizontal: SZ.x15},

  pv0: {paddingVertical: 0},
  pv05: {paddingVertical: SZ.x05},
  pv1: {paddingVertical: SZ.x1},
  pv2: {paddingVertical: SZ.x2},
  pv3: {paddingVertical: SZ.x3},
  pv4: {paddingVertical: SZ.x4},
  pv5: {paddingVertical: SZ.x5},
  pv6: {paddingVertical: SZ.x6},
  pv7: {paddingVertical: SZ.x7},
  pv8: {paddingVertical: SZ.x8},
  pv9: {paddingVertical: SZ.x9},
  pv10: {paddingVertical: SZ.x10},
  pv11: {paddingVertical: SZ.x11},
  pv12: {paddingVertical: SZ.x12},
  pv13: {paddingVertical: SZ.x13},
  pv14: {paddingVertical: SZ.x14},
  pv15: {paddingVertical: SZ.x15},

  pt0: {paddingTop: 0},
  pt05: {paddingTop: SZ.x05},
  pt1: {paddingTop: SZ.x1},
  pt2: {paddingTop: SZ.x2},
  pt3: {paddingTop: SZ.x3},
  pt4: {paddingTop: SZ.x4},
  pt5: {paddingTop: SZ.x5},
  pt6: {paddingTop: SZ.x6},
  pt7: {paddingTop: SZ.x7},
  pt8: {paddingTop: SZ.x8},
  pt9: {paddingTop: SZ.x9},
  pt10: {paddingTop: SZ.x10},
  pt11: {paddingTop: SZ.x11},
  pt12: {paddingTop: SZ.x12},
  pt13: {paddingTop: SZ.x13},
  pt14: {paddingTop: SZ.x14},
  pt15: {paddingTop: SZ.x15},

  pb0: {paddingBottom: 0},
  pb05: {paddingBottom: SZ.x05},
  pb1: {paddingBottom: SZ.x1},
  pb2: {paddingBottom: SZ.x2},
  pb3: {paddingBottom: SZ.x3},
  pb4: {paddingBottom: SZ.x4},
  pb5: {paddingBottom: SZ.x5},
  pb6: {paddingBottom: SZ.x6},
  pb7: {paddingBottom: SZ.x7},
  pb8: {paddingBottom: SZ.x8},
  pb9: {paddingBottom: SZ.x9},
  pb10: {paddingBottom: SZ.x10},
  pb11: {paddingBottom: SZ.x11},
  pb12: {paddingBottom: SZ.x12},
  pb13: {paddingBottom: SZ.x13},
  pb14: {paddingBottom: SZ.x14},
  pb15: {paddingBottom: SZ.x15},

  pl0: {paddingLeft: 0},
  pl05: {paddingLeft: SZ.x05},
  pl1: {paddingLeft: SZ.x1},
  pl2: {paddingLeft: SZ.x2},
  pl3: {paddingLeft: SZ.x3},
  pl4: {paddingLeft: SZ.x4},
  pl5: {paddingLeft: SZ.x5},
  pl6: {paddingLeft: SZ.x6},
  pl7: {paddingLeft: SZ.x7},
  pl8: {paddingLeft: SZ.x8},
  pl9: {paddingLeft: SZ.x9},
  pl10: {paddingLeft: SZ.x10},
  pl11: {paddingLeft: SZ.x11},
  pl12: {paddingLeft: SZ.x12},
  pl13: {paddingLeft: SZ.x13},
  pl14: {paddingLeft: SZ.x14},
  pl15: {paddingLeft: SZ.x15},

  pr0: {paddingRight: 0},
  pr05: {paddingRight: SZ.x05},
  pr1: {paddingRight: SZ.x1},
  pr2: {paddingRight: SZ.x2},
  pr3: {paddingRight: SZ.x3},
  pr4: {paddingRight: SZ.x4},
  pr5: {paddingRight: SZ.x5},
  pr6: {paddingRight: SZ.x6},
  pr7: {paddingRight: SZ.x7},
  pr8: {paddingRight: SZ.x8},
  pr9: {paddingRight: SZ.x9},
  pr10: {paddingRight: SZ.x10},
  pr11: {paddingRight: SZ.x11},
  pr12: {paddingRight: SZ.x12},
  pr13: {paddingRight: SZ.x13},
  pr14: {paddingRight: SZ.x14},
  pr15: {paddingRight: SZ.x15},

  m0: {marginStart: 0, marginEnd: 0, marginTop: 0, marginBottom: 0},
  m05: {margin: SZ.x05},
  m1: {margin: SZ.x1},
  m2: {margin: SZ.x2},
  m3: {margin: SZ.x3},
  m4: {margin: SZ.x4},
  m5: {margin: SZ.x5},
  m6: {margin: SZ.x6},
  m7: {margin: SZ.x7},
  m8: {margin: SZ.x8},
  m9: {margin: SZ.x9},
  m10: {margin: SZ.x10},
  m11: {margin: SZ.x11},
  m12: {margin: SZ.x12},
  m13: {margin: SZ.x13},
  m14: {margin: SZ.x14},
  m15: {margin: SZ.x15},

  mh0: {marginHorizontal: 0},
  mh05: {marginHorizontal: SZ.x05},
  mh1: {marginHorizontal: SZ.x1},
  mh2: {marginHorizontal: SZ.x2},
  mh3: {marginHorizontal: SZ.x3},
  mh4: {marginHorizontal: SZ.x4},
  mh5: {marginHorizontal: SZ.x5},
  mh6: {marginHorizontal: SZ.x6},
  mh7: {marginHorizontal: SZ.x7},
  mh8: {marginHorizontal: SZ.x8},
  mh9: {marginHorizontal: SZ.x9},
  mh10: {marginHorizontal: SZ.x10},
  mh11: {marginHorizontal: SZ.x11},
  mh12: {marginHorizontal: SZ.x12},
  mh13: {marginHorizontal: SZ.x13},
  mh14: {marginHorizontal: SZ.x14},
  mh15: {marginHorizontal: SZ.x15},

  mv0: {marginVertical: 0},
  mv05: {marginVertical: SZ.x05},
  mv1: {marginVertical: SZ.x1},
  mv2: {marginVertical: SZ.x2},
  mv3: {marginVertical: SZ.x3},
  mv4: {marginVertical: SZ.x4},
  mv5: {marginVertical: SZ.x5},
  mv6: {marginVertical: SZ.x6},
  mv7: {marginVertical: SZ.x7},
  mv8: {marginVertical: SZ.x8},
  mv9: {marginVertical: SZ.x9},
  mv10: {marginVertical: SZ.x10},
  mv11: {marginVertical: SZ.x11},
  mv12: {marginVertical: SZ.x12},
  mv13: {marginVertical: SZ.x13},
  mv14: {marginVertical: SZ.x14},
  mv15: {marginVertical: SZ.x15},

  mt0: {marginTop: 0},
  mt05: {marginTop: SZ.x05},
  mt1: {marginTop: SZ.x1},
  mt2: {marginTop: SZ.x2},
  mt3: {marginTop: SZ.x3},
  mt4: {marginTop: SZ.x4},
  mt5: {marginTop: SZ.x5},
  mt6: {marginTop: SZ.x6},
  mt7: {marginTop: SZ.x7},
  mt8: {marginTop: SZ.x8},
  mt9: {marginTop: SZ.x9},
  mt10: {marginTop: SZ.x10},
  mt11: {marginTop: SZ.x11},
  mt12: {marginTop: SZ.x12},
  mt13: {marginTop: SZ.x13},
  mt14: {marginTop: SZ.x14},
  mt15: {marginTop: SZ.x15},

  mb0: {marginBottom: 0},
  mb05: {marginBottom: SZ.x05},
  mb1: {marginBottom: SZ.x1},
  mb2: {marginBottom: SZ.x2},
  mb3: {marginBottom: SZ.x3},
  mb4: {marginBottom: SZ.x4},
  mb5: {marginBottom: SZ.x5},
  mb6: {marginBottom: SZ.x6},
  mb7: {marginBottom: SZ.x7},
  mb8: {marginBottom: SZ.x8},
  mb9: {marginBottom: SZ.x9},
  mb10: {marginBottom: SZ.x10},
  mb11: {marginBottom: SZ.x11},
  mb12: {marginBottom: SZ.x12},
  mb13: {marginBottom: SZ.x13},
  mb14: {marginBottom: SZ.x14},
  mb15: {marginBottom: SZ.x15},

  ml0: {marginLeft: 0},
  ml05: {marginLeft: SZ.x05},
  ml1: {marginLeft: SZ.x1},
  ml2: {marginLeft: SZ.x2},
  ml3: {marginLeft: SZ.x3},
  ml4: {marginLeft: SZ.x4},
  ml5: {marginLeft: SZ.x5},
  ml6: {marginLeft: SZ.x6},
  ml7: {marginLeft: SZ.x7},
  ml8: {marginLeft: SZ.x8},
  ml9: {marginLeft: SZ.x9},
  ml10: {marginLeft: SZ.x10},
  ml11: {marginLeft: SZ.x11},
  ml12: {marginLeft: SZ.x12},
  ml13: {marginLeft: SZ.x13},
  ml14: {marginLeft: SZ.x14},
  ml15: {marginLeft: SZ.x15},

  mr0: {marginRight: 0},
  mr05: {marginRight: SZ.x05},
  mr1: {marginRight: SZ.x1},
  mr2: {marginRight: SZ.x2},
  mr3: {marginRight: SZ.x3},
  mr4: {marginRight: SZ.x4},
  mr5: {marginRight: SZ.x5},
  mr6: {marginRight: SZ.x6},
  mr7: {marginRight: SZ.x7},
  mr8: {marginRight: SZ.x8},
  mr9: {marginRight: SZ.x9},
  mr10: {marginRight: SZ.x10},
  mr11: {marginRight: SZ.x11},
  mr12: {marginRight: SZ.x12},
  mr13: {marginRight: SZ.x13},
  mr14: {marginRight: SZ.x14},
  mr15: {marginRight: SZ.x15},

  gap0: {gap: 0},
  gap05: {gap: SZ.x05},
  gap1: {gap: SZ.x1},
  gap2: {gap: SZ.x2},
  gap3: {gap: SZ.x3},
  gap4: {gap: SZ.x4},
  gap5: {gap: SZ.x5},
  gap6: {gap: SZ.x6},
  gap7: {gap: SZ.x7},
  gap8: {gap: SZ.x8},
  gap9: {gap: SZ.x9},
  gap10: {gap: SZ.x10},
  gap11: {gap: SZ.x11},
  gap12: {gap: SZ.x12},
  gap13: {gap: SZ.x13},
  gap14: {gap: SZ.x14},
  gap15: {gap: SZ.x15},

  border: {borderWidth: 1, borderColor: 'transparent'},

  rad0: {borderRadius: 0},
  rad05: {borderRadius: SZ.x05},
  rad1: {borderRadius: SZ.x1},
  rad2: {borderRadius: SZ.x2},
  rad3: {borderRadius: SZ.x3},
  rad4: {borderRadius: SZ.x4},
  rad5: {borderRadius: SZ.x5},
  rad6: {borderRadius: SZ.x6},
  rad7: {borderRadius: SZ.x7},
  rad8: {borderRadius: SZ.x8},
  rad9: {borderRadius: SZ.x9},
  rad10: {borderRadius: SZ.x10},
  rad11: {borderRadius: SZ.x11},
  rad12: {borderRadius: SZ.x12},
  rad13: {borderRadius: SZ.x13},
  rad14: {borderRadius: SZ.x14},
  rad15: {borderRadius: SZ.x15},

  rt0: {borderTopLeftRadius: 0, borderTopRightRadius: 0},
  rt05: {borderTopLeftRadius: SZ.x05, borderTopRightRadius: SZ.x05},
  rt1: {borderTopLeftRadius: SZ.x1, borderTopRightRadius: SZ.x1},
  rt2: {borderTopLeftRadius: SZ.x2, borderTopRightRadius: SZ.x2},
  rt3: {borderTopLeftRadius: SZ.x3, borderTopRightRadius: SZ.x3},
  rt4: {borderTopLeftRadius: SZ.x4, borderTopRightRadius: SZ.x4},
  rt5: {borderTopLeftRadius: SZ.x5, borderTopRightRadius: SZ.x5},
  rt6: {borderTopLeftRadius: SZ.x6, borderTopRightRadius: SZ.x6},
  rt7: {borderTopLeftRadius: SZ.x7, borderTopRightRadius: SZ.x7},
  rt8: {borderTopLeftRadius: SZ.x8, borderTopRightRadius: SZ.x8},
  rt9: {borderTopLeftRadius: SZ.x9, borderTopRightRadius: SZ.x9},
  rt10: {borderTopLeftRadius: SZ.x10, borderTopRightRadius: SZ.x10},
  rt11: {borderTopLeftRadius: SZ.x11, borderTopRightRadius: SZ.x11},
  rt12: {borderTopLeftRadius: SZ.x12, borderTopRightRadius: SZ.x12},
  rt13: {borderTopLeftRadius: SZ.x13, borderTopRightRadius: SZ.x13},
  rt14: {borderTopLeftRadius: SZ.x14, borderTopRightRadius: SZ.x14},
  rt15: {borderTopLeftRadius: SZ.x15, borderTopRightRadius: SZ.x15},

  rb0: {borderBottomLeftRadius: 0, borderBottomRightRadius: 0},
  rb05: {borderBottomLeftRadius: SZ.x05, borderBottomRightRadius: SZ.x05},
  rb1: {borderBottomLeftRadius: SZ.x1, borderBottomRightRadius: SZ.x1},
  rb2: {borderBottomLeftRadius: SZ.x2, borderBottomRightRadius: SZ.x2},
  rb3: {borderBottomLeftRadius: SZ.x3, borderBottomRightRadius: SZ.x3},
  rb4: {borderBottomLeftRadius: SZ.x4, borderBottomRightRadius: SZ.x4},
  rb5: {borderBottomLeftRadius: SZ.x5, borderBottomRightRadius: SZ.x5},
  rb6: {borderBottomLeftRadius: SZ.x6, borderBottomRightRadius: SZ.x6},
  rb7: {borderBottomLeftRadius: SZ.x7, borderBottomRightRadius: SZ.x7},
  rb8: {borderBottomLeftRadius: SZ.x8, borderBottomRightRadius: SZ.x8},
  rb9: {borderBottomLeftRadius: SZ.x9, borderBottomRightRadius: SZ.x9},
  rb10: {borderBottomLeftRadius: SZ.x10, borderBottomRightRadius: SZ.x10},
  rb11: {borderBottomLeftRadius: SZ.x11, borderBottomRightRadius: SZ.x11},
  rb12: {borderBottomLeftRadius: SZ.x12, borderBottomRightRadius: SZ.x12},
  rb13: {borderBottomLeftRadius: SZ.x13, borderBottomRightRadius: SZ.x13},
  rb14: {borderBottomLeftRadius: SZ.x14, borderBottomRightRadius: SZ.x14},
  rb15: {borderBottomLeftRadius: SZ.x15, borderBottomRightRadius: SZ.x15},

  absolute: {position: 'absolute'},
  relative: {position: 'relative'},

  flex: {flex: 1},
  flex2: {flex: 2},
  flex3: {flex: 3},
  flex4: {flex: 4},
  flex5: {flex: 5},

  flexGrow: {flexGrow: 1},
  flexGrow2: {flexGrow: 2},

  // row: {flexDirection: 'row'},
  // rowReverse: {flexDirection: 'row-reverse'},
  // column: {flexDirection: 'column'},
  // columnReverse: {flexDirection: 'column-reverse'},

  wrap: {flexWrap: 'wrap'},
  noWrap: {flexWrap: 'nowrap'},
  wrapReverse: {flexWrap: 'wrap-reverse'},

  justify: {justifyContent: 'center'},
  justifyStart: {justifyContent: 'flex-start'},
  justifyEnd: {justifyContent: 'flex-end'},
  justifyBetween: {justifyContent: 'space-between'},
  justifyAround: {justifyContent: 'space-around'},
  justifyEvenly: {justifyContent: 'space-evenly'},

  align: {alignItems: 'center'},
  alignStart: {alignItems: 'flex-start'},
  alignEnd: {alignItems: 'flex-end'},
  alignStretch: {alignItems: 'stretch'},

  self: {alignSelf: 'center'},
  selfStart: {alignSelf: 'flex-start'},
  selfEnd: {alignSelf: 'flex-end'},
  selfStretch: {alignSelf: 'stretch'},

  shrink0: {flexShrink: 0},
  shrink1: {flexShrink: 1},

  wFull: {width: '100%'},
  hFull: {height: '100%'},

  text: {textAlign: 'center'},
  // textLeft: {textAlign: 'left'},
  // textRight: {textAlign: 'right'},

  bold: {fontWeight: '900'},
  // backdrop: {backgroundColor: 'rgba(155, 155, 155, 0.3)'},
  overflowHidden: {overflow: 'hidden'},
  // backward compatibility some will stay
  top0: {top: 0},
  bottom0: {bottom: 0},
  right0: {right: 0},
  left0: {left: 0},

  flexGrow1: {flexGrow: 1},
  flexShrink1: {flexShrink: 1},
  flexWrap: {flexWrap: 'wrap'},
  // safeTop: {paddingTop: Layout.statusBarHeight},
  fill: {width: '100%', height: '100%'},

  textJustify: {textAlign: 'justify'},
  textVCenter: {textAlignVertical: 'center'},
  textVTop: {textAlignVertical: 'top'},
  textVBottom: {textAlignVertical: 'bottom'},
  modalHeader: {textAlign: 'center', fontSize: 22, fontWeight: '500'},
  subsection: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 22,
  },
  sectionTitle: {
    textAlign: 'center',
    width: '100%',
    fontWeight: '900',
    fontSize: 26,
    marginTop: 24,
    marginBottom: 24,
  },
  symbolTitle: {
    // fontFamily: 'SFProText',
    fontSize: 17,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 22,
    letterSpacing: -0.41,
  },
  shadowXl: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,

    elevation: 10,
  },
  darkOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  transparentBackground: {backgroundColor: 'transparent'},
  radiusTop: {borderTopLeftRadius: 20, borderTopRightRadius: 20},
  radius: {borderRadius: 20},
  radius1: {borderRadius: 12},
  radius2: {borderRadius: 6},
  absoluteBottomCenter: {position: 'absolute', bottom: 10, left: 0, right: 0},
  onboardingInput: {
    borderWidth: 0,
    textAlign: 'left',
    borderRadius: 8,
    marginBottom: 0,
    padding: 16,
  },

  rounded50: {
    overflow: 'hidden',
    borderRadius: 50,
  },

  rowAround: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  divider: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: 'transparent',
    marginVertical: 12,
    width: '100%',
  },
  safeTop: {paddingTop: Layout.statusBarHeight},

  // start: {justifyContent: 'flex-start'},
  // end: {justifyContent: 'flex-end'},
  // flexEnd: {alignItems: 'flex-end'},
  // flexStart: {alignItems: 'flex-start'},
  // textRight: {textAlign: Platform.OS === 'web' ? ('end' as 'right') : 'right'},
  // textLeft: {textAlign: Platform.OS === 'web' ? ('start' as 'left') : 'left'},
  ...typographyStyles,
  ...SS(themes.dark),
});
