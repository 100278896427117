type ColorName = keyof typeof colorNames;

export const colorNames = {
  transparent: 'transparent',
  primary: 'primary',
  primary_pressed: 'primary_pressed',
  white: 'white',
  dark: 'dark',
  secondary: 'secondary',
  red: 'red',
  green: 'green',
  violet: 'violet',
  orange: 'orange',
  blue: 'blue',
  other: 'other',
  bg: 'bg',
  color: 'color',
  secondary_75: 'secondary_75',
  secondary_50: 'secondary_50',
  secondary_25: 'secondary_25',
  secondary_10: 'secondary_10',
  secondary_5: 'secondary_5',
  red_75: 'red_75',
  red_50: 'red_50',
  red_25: 'red_25',
  red_10: 'red_10',
  red_5: 'red_5',
  green_75: 'green_75',
  green_50: 'green_50',
  green_25: 'green_25',
  green_10: 'green_10',
  green_5: 'green_5',
  orange_75: 'orange_75',
  orange_50: 'orange_50',
  orange_25: 'orange_25',
  orange_10: 'orange_10',
  orange_5: 'orange_5',
  primary_75: 'primary_75',
  primary_50: 'primary_50',
  primary_25: 'primary_25',
  primary_10: 'primary_10',
  primary_5: 'primary_5',
} as const;

const Common = {
  transparent: 'transparent',
  primary: '#E60AF0',
  primary_pressed: '#F384F7',
  white: '#FFFFFF',
  dark: '#0D0D2B',
  secondary: '#662D91',
  red: '#D81515',
  green: '#00C35D',
  violet: '#8071E0',
  orange: '#D78E00',
  blue: '#0083E6',
  other: 'rgba(0, 0, 0, 0.7)',
} as const;

// eslint-disable-next-line no-unused-vars
export const lightTheme: {[key in ColorName]: string} = {
  bg: Common.white,
  color: Common.dark,

  secondary_75: '#4A4A60',
  secondary_50: '#868695',
  secondary_25: '#C2C2CA',
  secondary_10: '#E7E7EA',
  secondary_5: '#F3F3F4',

  red_75: '#E25050',
  red_50: '#EB8A8A',
  red_25: '#F5C4C4',
  red_10: '#FBE8E8',
  red_5: '#FDF3F3',

  green_75: '#40D285',
  green_50: '#80E1AE',
  green_25: '#BFF0D6',
  green_10: '#E5F9EF',
  green_5: '#F2FCF7',

  orange_75: '#E1AA40',
  orange_50: '#EBC780',
  orange_25: '#F5E3BF',
  orange_10: '#FBF4E5',
  orange_5: '#FDF9F2',

  primary_75: '#EC47F4',
  primary_50: Common.primary_pressed,
  primary_25: '#F9C2FB',
  primary_10: '#FCE6FD',
  primary_5: '#FEF3FE',
  ...Common,
} as const;

// eslint-disable-next-line no-unused-vars
export const darkTheme: {[key in ColorName]: string} = {
  bg: '#05051E',
  color: Common.white,

  secondary_75: '#C0C0C7',
  secondary_50: '#82828E',
  secondary_25: '#444456',
  secondary_10: '#1E1E35',
  secondary_5: '#121229',

  red_75: '#A31117',
  red_50: '#6F0D19',
  red_25: '#3A091C',
  red_10: '#1A071D',
  red_5: '#10061E',

  green_75: '#01934D',
  green_50: '#03643E',
  green_25: '#04352E',
  green_10: '#051824',
  green_5: '#050F21',

  orange_75: '#A26C08',
  orange_50: '#6E4A0F',
  orange_25: '#3A2717',
  orange_10: '#1A131B',
  orange_5: '#100C1D',

  primary_75: '#AE09BC',
  primary_50: '#760887',
  primary_25: '#3D0653',
  primary_10: '#1C0633',
  primary_5: '#100529',

  ...Common,
} as const;

export const themes = {
  light: lightTheme,
  dark: darkTheme,
} as const;

export const themeNames = {
  light: 'light',
  dark: 'dark',
} as const;
