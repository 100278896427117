import {Dimensions, Platform, StatusBar} from 'react-native';
import {initialWindowMetrics} from 'react-native-safe-area-context';
const width = Platform.OS === 'web' ? 620 : Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const X_WIDTH = 375,
  X_HEIGHT = 812,
  XSMAX_WIDTH = 414,
  XSMAX_HEIGHT = 896;

export const isIPhoneX = () =>
  Platform.OS === 'ios' && !Platform.isPad
    ? (width === X_WIDTH && height === X_HEIGHT) ||
      (width === XSMAX_WIDTH && height === XSMAX_HEIGHT)
    : false;

export const getDimensions = () => ({
  width: Number(initialWindowMetrics?.frame.width),
  height: Number(initialWindowMetrics?.frame.height),
});
export const StatusBarHeight = Platform.select({
  ios: 44,
  android: StatusBar.currentHeight,
  default: 20,
});

export default {
  statusBarHeight: StatusBarHeight,
  window: {
    width,
    height,
  },
  isSmallDevice: width < 375,
};
