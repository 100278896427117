import {IconProps} from '../types';
import {Mask, Path, Svg} from 'react-native-svg';

export const Clock = ({size = 24, color, accent}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Mask id="path-1Inside-1_1_736" fill="white">
      <Path d="M18.0014 11.5C18.5529 11.5 19.0061 11.9486 18.9414 12.4963C18.7794 13.8693 18.2841 15.1875 17.4923 16.333C16.5104 17.7535 15.1193 18.8413 13.5039 19.4515C11.8886 20.0617 10.1257 20.1655 8.44991 19.7491C6.77413 19.3326 5.26493 18.4156 4.12319 17.1202C2.98146 15.8247 2.26136 14.2123 2.05873 12.4974C1.8561 10.7826 2.18055 9.04674 2.98891 7.52087C3.79726 5.995 5.05117 4.75152 6.58374 3.95593C7.81972 3.31432 9.18965 2.98856 10.5722 3.0003C11.1236 3.00498 11.5117 3.51088 11.4425 4.058V4.058C11.3732 4.60512 10.8719 4.98437 10.3206 4.99959C9.34299 5.02657 8.3795 5.2739 7.50389 5.72845C6.3314 6.33711 5.3721 7.28843 4.75367 8.45578C4.13525 9.62314 3.88702 10.9512 4.04204 12.2631C4.19707 13.575 4.74798 14.8086 5.62146 15.7997C6.49493 16.7908 7.64954 17.4923 8.93159 17.8109C10.2136 18.1295 11.5623 18.0501 12.7981 17.5833C14.0339 17.1164 15.0983 16.2842 15.8494 15.1975C16.4103 14.3859 16.7768 13.4612 16.9264 12.4947C17.0107 11.9497 17.45 11.5 18.0014 11.5V11.5Z" />
    </Mask>
    <Path
      d="M18.0014 11.5C18.5529 11.5 19.0061 11.9486 18.9414 12.4963C18.7794 13.8693 18.2841 15.1875 17.4923 16.333C16.5104 17.7535 15.1193 18.8413 13.5039 19.4515C11.8886 20.0617 10.1257 20.1655 8.44991 19.7491C6.77413 19.3326 5.26493 18.4156 4.12319 17.1202C2.98146 15.8247 2.26136 14.2123 2.05873 12.4974C1.8561 10.7826 2.18055 9.04674 2.98891 7.52087C3.79726 5.995 5.05117 4.75152 6.58374 3.95593C7.81972 3.31432 9.18965 2.98856 10.5722 3.0003C11.1236 3.00498 11.5117 3.51088 11.4425 4.058V4.058C11.3732 4.60512 10.8719 4.98437 10.3206 4.99959C9.34299 5.02657 8.3795 5.2739 7.50389 5.72845C6.3314 6.33711 5.3721 7.28843 4.75367 8.45578C4.13525 9.62314 3.88702 10.9512 4.04204 12.2631C4.19707 13.575 4.74798 14.8086 5.62146 15.7997C6.49493 16.7908 7.64954 17.4923 8.93159 17.8109C10.2136 18.1295 11.5623 18.0501 12.7981 17.5833C14.0339 17.1164 15.0983 16.2842 15.8494 15.1975C16.4103 14.3859 16.7768 13.4612 16.9264 12.4947C17.0107 11.9497 17.45 11.5 18.0014 11.5V11.5Z"
      fill={color}
      stroke={color}
      strokeWidth="4"
      strokeLinejoin="round"
      mask="url(#path-1Inside-1_1_736)"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 6.75C10.9142 6.75 11.25 7.08579 11.25 7.5V11.5648L14.3721 13.3488C14.7317 13.5543 14.8567 14.0125 14.6512 14.3721C14.4457 14.7317 13.9875 14.8567 13.6279 14.6512L10.1279 12.6512C9.89421 12.5177 9.75 12.2691 9.75 12V7.5C9.75 7.08579 10.0858 6.75 10.5 6.75Z"
      fill={accent}
    />
    <Path
      d="M15.6503 8.5898H16.9169V3.95264L15.6503 3.95264L14.3809 4.75569V5.77142L15.5851 5.01604H15.6511V8.58906L15.6503 8.5898Z"
      fill={accent}
    />
    <Path
      d="M20.0213 8.71447C21.194 8.71447 22 8.0427 22 7.04383V7.03723C22 6.16011 21.329 5.53014 20.402 5.53014C19.9106 5.53014 19.527 5.73915 19.3364 6.05084H19.2704L19.3708 4.85909H21.692V3.95264L18.3881 3.95264L18.174 6.74168H19.2572C19.3122 6.64854 19.3921 6.55834 19.4926 6.49086C19.6341 6.39112 19.8109 6.33685 20.0221 6.33685C20.4856 6.33685 20.8215 6.63241 20.8215 7.05997V7.06657C20.8215 7.49706 20.4929 7.79921 20.0221 7.79921C19.6107 7.79921 19.3129 7.5682 19.233 7.28218L19.2293 7.26311H18.0948L18.0984 7.31445C18.1505 8.10503 18.8802 8.71521 20.0221 8.71521L20.0213 8.71447Z"
      fill={accent}
    />
  </Svg>
);

export const Lightning = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      d="M13.2692 2.5L12.6538 10.5556H18.5L11.4231 22.5L12.3462 14.1667H6.5L13.2692 2.5Z"
      fill={color}
    />
    <Path
      d="M12.2692 1.5L11.6538 9.55556H17.5L10.4231 21.5L11.3462 13.1667H5.5L12.2692 1.5Z"
      fill={color}
    />
    <Path
      d="M12.1538 10.6438H16.9677L11.8082 19.3521L12.4308 13.7314L12.5031 13.0784H11.8462H7.02139L11.9867 4.52076L11.5673 10.0107L11.519 10.6438H12.1538Z"
      fill={color}
      stroke={color}
      strokeWidth="1.17647"
    />
  </Svg>
);
