import {themes} from './consts/themes';
import {createContext} from 'react';

export interface UIContextType {
  theme: 'dark' | 'light';
  locale: 'en' | 'ar';
  setTheme?: (theme: 'dark' | 'light') => void;
  setLocale?: (locale: 'en' | 'ar') => void;
  overrideTheme?: Partial<typeof themes.dark>;
}

export const UIContext = createContext<UIContextType>({theme: 'light', locale: 'en'});
