import {SZ} from '../consts';
import {View} from '../View';
import {ColorsType, useColors, useStyle} from '../hooks';
import {useEffect, useRef} from 'react';
import {Animated, StyleProp, ViewStyle} from 'react-native';
import {useLayout} from '@react-native-community/hooks';

export const ProgressBar = ({
  progress,
  color = 'green',
  unfilledColor = 'secondary_10',
  style,
}: {
  progress: number;
  color: ColorsType;
  unfilledColor?: ColorsType;
  rtl?: boolean;
  style?: StyleProp<ViewStyle>;
}) => {
  const colors = useColors();
  const S = useStyle();

  const width = useRef(new Animated.Value(0));
  const {onLayout, width: widthLayout} = useLayout();

  useEffect(() => {
    Animated.timing(width.current, {
      toValue: Math.min(widthLayout * progress, widthLayout),
      duration: 2000,
      useNativeDriver: false,
    }).start();
  }, [progress, widthLayout]);

  return (
    <View onLayout={onLayout} bg={unfilledColor} style={[{height: SZ.x1}, S.rad1, style]}>
      <Animated.View
        style={[S.rad1, {height: SZ.x1, backgroundColor: colors[color]}, {width: width.current}]}
      />
    </View>
  );
};
