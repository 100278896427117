import {themes, themeNames} from '../consts/themes';
import {S, SS} from '../consts/styles';
import {UIContext} from '../context';
import {useContext} from 'react';

export type ColorsType = keyof ReturnType<typeof useColors>;
const map = {
  light: Object.assign({}, S, SS(themes.light)),
  dark: Object.assign({}, S, SS(themes.dark)),
};

export function useColors() {
  const [, theme] = useAppTheme();
  return theme;
}

export function useStyle() {
  const [theme] = useAppTheme();
  return map[theme];
}

export function useAppTheme() {
  const {theme, overrideTheme} = useContext(UIContext);
  return theme === themeNames.dark
    ? ([themeNames.dark, Object.assign({}, themes.dark, overrideTheme), () => {}] as const)
    : ([themeNames.light, Object.assign({}, themes.light, overrideTheme), () => {}] as const);
}
