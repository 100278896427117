import {IconProps} from '../types';
import {Path, Rect, Svg} from 'react-native-svg';

export const AddSymbol = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C12.5523 2 13 2.44772 13 3V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V3C11 2.44772 11.4477 2 12 2Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12Z"
      fill={color}
    />
  </Svg>
);

export const Back = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7071 3.29289C17.0976 3.68342 17.0976 4.31658 16.7071 4.70711L9.41421 12L16.7071 19.2929C17.0976 19.6834 17.0976 20.3166 16.7071 20.7071C16.3166 21.0976 15.6834 21.0976 15.2929 20.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L15.2929 3.29289C15.6834 2.90237 16.3166 2.90237 16.7071 3.29289Z"
      fill={color}
    />
  </Svg>
);

export const BackArrow = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7071 5.29289C11.0976 5.68342 11.0976 6.31658 10.7071 6.70711L6.41421 11L20 11C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13L6.41421 13L10.7071 17.2929C11.0976 17.6834 11.0976 18.3166 10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929L9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289Z"
      fill={color}
    />
  </Svg>
);

export const Check = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29292 11.2929C5.68345 10.9024 6.31661 10.9024 6.70714 11.2929L10 14.5858L17.2929 7.29289C17.6834 6.90237 18.3166 6.90237 18.7071 7.29289C19.0977 7.68342 19.0977 8.31658 18.7071 8.70711L10.7071 16.7071C10.5196 16.8946 10.2652 17 10 17C9.73481 17 9.48046 16.8946 9.29292 16.7071L5.29292 12.7071C4.9024 12.3166 4.9024 11.6834 5.29292 11.2929Z"
      fill={color}
    />
  </Svg>
);

export const DownArrow = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 13.2929C5.68342 12.9024 6.31658 12.9024 6.70711 13.2929L11 17.5858L11 4C11 3.44772 11.4477 3 12 3C12.5523 3 13 3.44772 13 4L13 17.5858L17.2929 13.2929C17.6834 12.9024 18.3166 12.9024 18.7071 13.2929C19.0976 13.6834 19.0976 14.3166 18.7071 14.7071L12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L5.29289 14.7071C4.90237 14.3166 4.90237 13.6834 5.29289 13.2929Z"
      fill={color}
    />
  </Svg>
);

export const DropDown = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.29289 7.29289C3.68342 6.90237 4.31658 6.90237 4.70711 7.29289L12 14.5858L19.2929 7.29289C19.6834 6.90237 20.3166 6.90237 20.7071 7.29289C21.0976 7.68342 21.0976 8.31658 20.7071 8.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L3.29289 8.70711C2.90237 8.31658 2.90237 7.68342 3.29289 7.29289Z"
      fill={color}
    />
  </Svg>
);

export const DropUp = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L20.7071 15.2929C21.0976 15.6834 21.0976 16.3166 20.7071 16.7071C20.3166 17.0976 19.6834 17.0976 19.2929 16.7071L12 9.41421L4.70711 16.7071C4.31658 17.0976 3.68342 17.0976 3.29289 16.7071C2.90237 16.3166 2.90237 15.6834 3.29289 15.2929L11.2929 7.29289Z"
      fill={color}
    />
  </Svg>
);

export const Forward = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29289 3.29289C7.68342 2.90237 8.31658 2.90237 8.70711 3.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L8.70711 20.7071C8.31658 21.0976 7.68342 21.0976 7.29289 20.7071C6.90237 20.3166 6.90237 19.6834 7.29289 19.2929L14.5858 12L7.29289 4.70711C6.90237 4.31658 6.90237 3.68342 7.29289 3.29289Z"
      fill={color}
    />
  </Svg>
);

export const MinusSymbol = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C12 6.55228 11.5523 7 11 7L1 7C0.447715 7 -6.78525e-08 6.55228 -4.37114e-08 6C-1.95703e-08 5.44771 0.447715 5 1 5L11 5C11.5523 5 12 5.44772 12 6Z"
      fill={color}
    />
  </Svg>
);

export const ProfileMore = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      d="M4 12C4 11.4696 4.21071 10.9609 4.58579 10.5858C4.96086 10.2107 5.46957 10 6 10C6.53043 10 7.03914 10.2107 7.41421 10.5858C7.78929 10.9609 8 11.4696 8 12C8 12.5304 7.78929 13.0391 7.41421 13.4142C7.03914 13.7893 6.53043 14 6 14C5.46957 14 4.96086 13.7893 4.58579 13.4142C4.21071 13.0391 4 12.5304 4 12ZM10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12ZM16 12C16 11.4696 16.2107 10.9609 16.5858 10.5858C16.9609 10.2107 17.4696 10 18 10C18.5304 10 19.0391 10.2107 19.4142 10.5858C19.7893 10.9609 20 11.4696 20 12C20 12.5304 19.7893 13.0391 19.4142 13.4142C19.0391 13.7893 18.5304 14 18 14C17.4696 14 16.9609 13.7893 16.5858 13.4142C16.2107 13.0391 16 12.5304 16 12Z"
      fill={color}
    />
  </Svg>
);

export const RightArrow = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2929 18.7071C12.9023 18.3166 12.9023 17.6834 13.2929 17.2929L17.5858 13L3.99997 13C3.44768 13 2.99997 12.5523 2.99997 12C2.99997 11.4477 3.44768 11 3.99997 11L17.5858 11L13.2929 6.70711C12.9023 6.31658 12.9023 5.68342 13.2929 5.29289C13.6834 4.90237 14.3166 4.90237 14.7071 5.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L14.7071 18.7071C14.3166 19.0976 13.6834 19.0976 13.2929 18.7071Z"
      fill={color}
    />
  </Svg>
);

export const Sort = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path d="M14 14L12 16L10 14H14Z" fill={color} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.07612 13.6173C9.2309 13.2436 9.59554 13 10 13H14C14.4045 13 14.7691 13.2436 14.9239 13.6173C15.0787 13.991 14.9931 14.4211 14.7071 14.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L9.29289 14.7071C9.00689 14.4211 8.92134 13.991 9.07612 13.6173Z"
      fill={color}
    />
    <Path d="M10 10L12 8L14 10L10 10Z" fill={color} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9239 10.3827C14.7691 10.7564 14.4045 11 14 11L10 11C9.59554 11 9.2309 10.7564 9.07612 10.3827C8.92134 10.009 9.0069 9.57889 9.29289 9.29289L11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L14.7071 9.29289C14.9931 9.57889 15.0787 10.009 14.9239 10.3827Z"
      fill={color}
    />
  </Svg>
);

export const Union = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.34285 4.92888C5.95232 4.53836 5.31916 4.53836 4.92864 4.92888C4.53811 5.3194 4.53811 5.95257 4.92864 6.34309L10.5858 12.0002L4.92923 17.6568C4.53871 18.0473 4.5387 18.6805 4.92923 19.071C5.31975 19.4615 5.95292 19.4615 6.34344 19.071L12 13.4145L17.6566 19.071C18.0471 19.4615 18.6802 19.4615 19.0708 19.071C19.4613 18.6805 19.4613 18.0473 19.0708 17.6568L13.4142 12.0002L19.0714 6.34309C19.4619 5.95257 19.4619 5.3194 19.0714 4.92888C18.6808 4.53836 18.0477 4.53836 17.6572 4.92888L12 10.586L6.34285 4.92888Z"
      fill={color}
    />
  </Svg>
);

export const UpArrow = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7071 10.7071C18.3166 11.0976 17.6834 11.0976 17.2929 10.7071L13 6.41421L13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20L11 6.41421L6.70711 10.7071C6.31658 11.0976 5.68342 11.0976 5.29289 10.7071C4.90237 10.3166 4.90237 9.68342 5.29289 9.29289L11.2929 3.29289C11.6834 2.90237 12.3166 2.90237 12.7071 3.29289L18.7071 9.29289C19.0976 9.68342 19.0976 10.3166 18.7071 10.7071Z"
      fill={color}
    />
  </Svg>
);

export const Down = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.152 9.235A2 2 0 018 8h8a2 2 0 011.414 3.414l-4 4a2 2 0 01-2.828 0l-4-4a2 2 0 01-.434-2.18z"
      fill={color}
    />
  </Svg>
);
export const Up = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path d="M8 14l4-4 4 4H8z" fill="#fff" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.848 14.765A2 2 0 0116 16H8a2 2 0 01-1.414-3.414l4-4a2 2 0 012.828 0l4 4a2 2 0 01.434 2.18z"
      fill={color}
    />
  </Svg>
);

export const Radio = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 25" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12.5c0 6.075 4.925 11 11 11s11-4.925 11-11-4.925-11-11-11-11 4.925-11 11zm11-12C5.373.5 0 5.873 0 12.5s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z"
      fill={color}
    />
  </Svg>
);

export const RadioChecked = ({size = 24, color, accent}: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 25" fill="none">
      <Rect width={24} height={24} rx={12} fill={color} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.293 11.293a1 1 0 011.414 0L10 14.586l7.293-7.293a1 1 0 011.414 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        fill={accent}
      />
    </Svg>
  );
};

export const CircleCheckbox = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0Z"
      fill={color}
    />
  </Svg>
);
export const CircleCheckboxChecked = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Rect width={size} height={size} rx={(size as number) / 2} fill={color} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.2928 11.2929C5.68332 10.9024 6.31649 10.9024 6.70701 11.2929L9.99991 14.5858L17.2928 7.29289C17.6833 6.90237 18.3165 6.90237 18.707 7.29289C19.0975 7.68342 19.0975 8.31658 18.707 8.70711L10.707 16.7071C10.5195 16.8946 10.2651 17 9.99991 17C9.73469 17 9.48034 16.8946 9.2928 16.7071L5.2928 12.7071C4.90228 12.3166 4.90228 11.6834 5.2928 11.2929Z"
      fill="white"
    />
  </Svg>
);

export const RadioClose = ({size = 24, color, accent}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <Rect width={size} height={size} rx={(size as number) / 2} fill={color} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.22865 3.28596C3.9683 3.02561 3.54619 3.02561 3.28584 3.28596C3.02549 3.54631 3.02549 3.96842 3.28584 4.22877L7.05727 8.0002L3.28623 11.7712C3.02588 12.0316 3.02588 12.4537 3.28623 12.714C3.54658 12.9744 3.96869 12.9744 4.22904 12.714L8.00008 8.94301L11.7711 12.714C12.0315 12.9744 12.4536 12.9744 12.7139 12.714C12.9743 12.4537 12.9743 12.0316 12.7139 11.7712L8.94289 8.0002L12.7143 4.22877C12.9747 3.96842 12.9747 3.54631 12.7143 3.28596C12.454 3.02561 12.0319 3.02561 11.7715 3.28596L8.00008 7.05739L4.22865 3.28596Z"
      fill={accent}
    />
  </Svg>
);
