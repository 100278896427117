import {IconProps} from '../types';
import {ClipPath, Defs, G, Path, Svg} from 'react-native-svg';

export const Add = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7C2 5.34315 3.34315 4 5 4H12C12.5523 4 13 4.44772 13 5C13 5.55228 12.5523 6 12 6H5C4.44772 6 4 6.44772 4 7V19C4 19.5523 4.44772 20 5 20H17C17.5523 20 18 19.5523 18 19V12C18 11.4477 18.4477 11 19 11C19.5523 11 20 11.4477 20 12V19C20 20.6569 18.6569 22 17 22H5C3.34315 22 2 20.6569 2 19V7Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 9C6 8.44772 6.44772 8 7 8H15C15.5523 8 16 8.44772 16 9C16 9.55228 15.5523 10 15 10H7C6.44772 10 6 9.55228 6 9ZM6 13C6 12.4477 6.44772 12 7 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H7C6.44772 14 6 13.5523 6 13ZM6 17C6 16.4477 6.44772 16 7 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H7C6.44772 18 6 17.5523 6 17Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2C20 1.44772 19.5523 1 19 1C18.4477 1 18 1.44772 18 2V4H16C15.4477 4 15 4.44771 15 5C15 5.55228 15.4477 6 16 6H18V8C18 8.55228 18.4477 9 19 9C19.5523 9 20 8.55228 20 8V6H22C22.5523 6 23 5.55228 23 5C23 4.44772 22.5523 4 22 4H20V2Z"
      fill={color}
    />
  </Svg>
);

export const ApplePay = ({size = 24, color}: IconProps) => (
  <Svg fill={color || 'black'} width={size} height={size} viewBox="-52.01 0 560.035 560.035">
    <Path d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655" />
  </Svg>
);

export const GooglePay = ({size = 24}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
      fill="#4285F4"
    />
    <Path
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
      fill="#34A853"
    />
    <Path
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
      fill="#FBBC05"
    />
    <Path
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
      fill="#EA4335"
    />
    <Path d="M1 1h22v22H1z" fill="none" />
  </Svg>
);

export const CardDeposit = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4C3.34315 4 2 5.34315 2 7L2 11L2 17C2 18.6569 3.34315 20 5 20H19C20.6569 20 22 18.6569 22 17V7C22 5.34315 20.6569 4 19 4L5 4ZM4 12L15 12C15.5523 12 16 11.5523 16 11C16 10.4477 15.5523 10 15 10L4 10L4 7C4 6.44772 4.44772 6 5 6L19 6C19.5523 6 20 6.44772 20 7V17C20 17.5523 19.5523 18 19 18H5C4.44772 18 4 17.5523 4 17L4 12Z"
      fill={color}
    />
  </Svg>
);

export const CopyIc = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 7.5C6 6.94772 6.44772 6.5 7 6.5H11C11.5523 6.5 12 6.94772 12 7.5C12 8.05228 11.5523 8.5 11 8.5H7C6.44772 8.5 6 8.05228 6 7.5ZM6 10.5C6 9.94772 6.44772 9.5 7 9.5H11C11.5523 9.5 12 9.94772 12 10.5C12 11.0523 11.5523 11.5 11 11.5H7C6.44772 11.5 6 11.0523 6 10.5ZM6 13.5C6 12.9477 6.44772 12.5 7 12.5H9C9.55228 12.5 10 12.9477 10 13.5C10 14.0523 9.55228 14.5 9 14.5H7C6.44772 14.5 6 14.0523 6 13.5Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2C3.34315 2 2 3.34315 2 5L2 16C2 17.6569 3.34315 19 5 19H7C7 20.6569 8.34315 22 10 22L19 22C20.6569 22 22 20.6569 22 19L22 7C22 5.34315 20.6569 4 19 4H15.8293C15.4175 2.83481 14.3062 2 13 2L5 2ZM14 5C14 4.44772 13.5523 4 13 4L5 4C4.44772 4 4 4.44772 4 5L4 16C4 16.5523 4.44772 17 5 17H8H13C13.5523 17 14 16.5523 14 16L14 5ZM9 19C9 19.5523 9.44772 20 10 20L19 20C19.5523 20 20 19.5523 20 19L20 7C20 6.44772 19.5523 6 19 6H16V16C16 17.6569 14.6569 19 13 19H9Z"
      fill={color}
    />
  </Svg>
);

export const Deposits = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 15C19 14.4477 18.5523 14 18 14C17.4477 14 17 14.4477 17 15V17H15C14.4477 17 14 17.4477 14 18C14 18.5523 14.4477 19 15 19H17V21C17 21.5523 17.4477 22 18 22C18.5523 22 19 21.5523 19 21V19H21C21.5523 19 22 18.5523 22 18C22 17.4477 21.5523 17 21 17H19V15Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3C3.34315 3 2 4.34315 2 6V10V18C2 19.6569 3.34315 21 5 21H10C10.5523 21 11 20.5523 11 20C11 19.4477 10.5523 19 10 19H5C4.44772 19 4 18.5523 4 18V11H21C21.5523 11 22 10.5523 22 10V6C22 4.34315 20.6569 3 19 3H5ZM20 9V6C20 5.44772 19.5523 5 19 5H5C4.44772 5 4 5.44772 4 6V9H20Z"
      fill={color}
    />
  </Svg>
);

export const DollarSign = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V4.00019C8.77414 4.02016 6.88056 5.63598 6.51396 7.8356C6.14652 10.0403 7.59489 12.1387 9.78656 12.577L11 12.8197L11 17.9348C9.82392 17.7005 8.83877 16.853 8.44904 15.6838C8.27439 15.1598 7.70807 14.8767 7.18413 15.0513C6.66018 15.226 6.37702 15.7923 6.55167 16.3162C7.21426 18.304 8.95899 19.7072 11 19.9593L11 21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21L13 19.9998C15.2262 19.9802 17.1201 18.3643 17.4868 16.1644C17.8542 13.9597 16.4058 11.8613 14.2142 11.423L13 11.1801V6.06509C14.1764 6.29919 15.1619 7.14681 15.5517 8.31623C15.7263 8.84017 16.2926 9.12333 16.8166 8.94868C17.3405 8.77404 17.6237 8.20772 17.449 7.68377C16.7864 5.69577 15.0413 4.29251 13 4.04062V3ZM11 6.00033C9.75185 6.02019 8.69262 6.92917 8.48675 8.1644C8.29677 9.30428 9.04563 10.3892 10.1788 10.6159L11 10.7801V6.00033ZM13 13.2197V17.9997C14.2485 17.9802 15.3081 17.0711 15.514 15.8356C15.7039 14.6957 14.9551 13.6108 13.8219 13.3841L13 13.2197Z"
      fill={color}
    />
  </Svg>
);

export const DragAndDrop = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path d="M13.5 20L12 21L10.5 20H13.5Z" fill={color} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.54291 19.7102C9.67061 19.2885 10.0593 19 10.5 19H13.5C13.9407 19 14.3294 19.2885 14.4571 19.7102C14.5848 20.132 14.4214 20.5876 14.0547 20.8321L12.5547 21.8321C12.2188 22.056 11.7812 22.056 11.4453 21.8321L9.9453 20.8321C9.57864 20.5876 9.41521 20.132 9.54291 19.7102Z"
      fill={color}
    />
    <Path d="M10.5 4L12 3L13.5 4L10.5 4Z" fill={color} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4571 4.28978C14.3294 4.71154 13.9407 5 13.5 5L10.5 5C10.0593 5 9.6706 4.71154 9.54291 4.28978C9.41521 3.86802 9.57864 3.41239 9.9453 3.16795L11.4453 2.16795C11.7812 1.94402 12.2188 1.94402 12.5547 2.16795L14.0547 3.16795C14.4214 3.41239 14.5848 3.86803 14.4571 4.28978Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 8C20 8.55228 19.5523 9 19 9H5C4.44772 9 4 8.55228 4 8C4 7.44772 4.44772 7 5 7L19 7C19.5523 7 20 7.44772 20 8Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 12.5523 21.5523 13 21 13L3 13C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11L21 11C21.5523 11 22 11.4477 22 12Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 16C20 16.5523 19.5523 17 19 17H5C4.44772 17 4 16.5523 4 16C4 15.4477 4.44772 15 5 15L19 15C19.5523 15 20 15.4477 20 16Z"
      fill={color}
    />
  </Svg>
);

export const SearchBase = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2929 16.2929C16.6834 15.9024 17.3166 15.9024 17.7071 16.2929L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L16.2929 17.7071C15.9024 17.3166 15.9024 16.6834 16.2929 16.2929Z"
      fill={color}
    />
  </Svg>
);

export const StatementActive = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V8.24264C19 7.71221 18.7893 7.2035 18.4142 6.82843L16.1716 4.58579C15.7965 4.21071 15.2878 4 14.7574 4H7ZM3 6C3 3.79086 4.79086 2 7 2H14.7574C15.8182 2 16.8356 2.42143 17.5858 3.17157L19.8284 5.41421C20.5786 6.16436 21 7.18178 21 8.24264V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V6Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 6.5C12.75 6.08579 12.4142 5.75 12 5.75C11.5858 5.75 11.25 6.08579 11.25 6.5V7.25143C9.91856 7.2936 8.79227 8.26472 8.56123 9.585C8.32417 10.9396 9.21837 12.2337 10.5693 12.491L11.25 12.6206V15.189C10.6806 15.0372 10.2091 14.6128 10.0079 14.038C9.87106 13.647 9.44319 13.441 9.05224 13.5778C8.66128 13.7147 8.45527 14.1425 8.59211 14.5335C9.00468 15.7123 10.0387 16.5452 11.25 16.7171V17.5C11.25 17.9142 11.5858 18.25 12 18.25C12.4142 18.25 12.75 17.9142 12.75 17.5V16.7486C14.0814 16.7064 15.2077 15.7353 15.4388 14.415C15.6758 13.0604 14.7816 11.7664 13.4307 11.509L12.75 11.3794V8.81097C13.3194 8.96276 13.7909 9.3872 13.9921 9.96205C14.1289 10.353 14.5568 10.559 14.9478 10.4222C15.3387 10.2853 15.5447 9.85748 15.4079 9.46652C14.9953 8.28773 13.9613 7.45477 12.75 7.28287V6.5ZM11.25 8.75306C10.6475 8.79398 10.1442 9.24118 10.0388 9.84357C9.94301 10.3908 10.3042 10.9135 10.8499 11.0175L11.25 11.0937V8.75306ZM12.75 12.9063V15.2469C13.3525 15.206 13.8558 14.7588 13.9612 14.1564C14.057 13.6092 13.6958 13.0865 13.1501 12.9825L12.75 12.9063Z"
      fill={color}
    />
  </Svg>
);

export const WireTransfer = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5939 2.08619C11.8524 1.97127 12.1476 1.97127 12.4061 2.08619L21.4061 6.08619C21.7673 6.24669 22 6.60481 22 7V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V7.64987L12 4.09432L4 7.64987V9C4 9.55228 3.55228 10 3 10C2.44772 10 2 9.55228 2 9L2 7C2 6.60481 2.23273 6.24669 2.59386 6.08619L11.5939 2.08619ZM12 8.5C12.5523 8.5 13 8.94772 13 9.5V15.5C13 16.0523 12.5523 16.5 12 16.5C11.4477 16.5 11 16.0523 11 15.5V9.5C11 8.94772 11.4477 8.5 12 8.5ZM4 16.5C4 15.9477 3.55228 15.5 3 15.5C2.44772 15.5 2 15.9477 2 16.5V17C2 19.2091 3.79086 21 6 21L18 21C20.2091 21 22 19.2091 22 17L22 16.5C22 15.9477 21.5523 15.5 21 15.5C20.4477 15.5 20 15.9477 20 16.5L20 17C20 18.1046 19.1046 19 18 19L6 19C4.89543 19 4 18.1046 4 17V16.5ZM16 10C16.5523 10 17 10.4477 17 11V14C17 14.5523 16.5523 15 16 15C15.4477 15 15 14.5523 15 14V11C15 10.4477 15.4477 10 16 10ZM9 11C9 10.4477 8.55228 10 8 10C7.44772 10 7 10.4477 7 11L7 14C7 14.5523 7.44772 15 8 15C8.55228 15 9 14.5523 9 14L9 11Z"
      fill={color}
    />
  </Svg>
);

export const Withdraw = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 18C22 18.5523 21.5523 19 21 19L15 19C14.4477 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17L21 17C21.5523 17 22 17.4477 22 18Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3C3.34315 3 2 4.34315 2 6V10V18C2 19.6569 3.34315 21 5 21H10C10.5523 21 11 20.5523 11 20C11 19.4477 10.5523 19 10 19H5C4.44772 19 4 18.5523 4 18V11H21C21.5523 11 22 10.5523 22 10V6C22 4.34315 20.6569 3 19 3H5ZM20 9V6C20 5.44772 19.5523 5 19 5H5C4.44772 5 4 5.44772 4 6V9H20Z"
      fill={color}
    />
  </Svg>
);

export const BidOffer = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4C10 3.44772 10.4477 3 11 3H20C20.5523 3 21 3.44772 21 4C21 4.55228 20.5523 5 20 5H11C10.4477 5 10 4.55228 10 4Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4C2 3.44772 2.44772 3 3 3H7C7.55228 3 8 3.44772 8 4C8 4.55228 7.55228 5 7 5H3C2.44772 5 2 4.55228 2 4Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 16C2 15.4477 2.44772 15 3 15H7C7.55228 15 8 15.4477 8 16C8 16.5523 7.55228 17 7 17H3C2.44772 17 2 16.5523 2 16Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 16C10 15.4477 10.4477 15 11 15H21C21.5523 15 22 15.4477 22 16C22 16.5523 21.5523 17 21 17H11C10.4477 17 10 16.5523 10 16Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8C10 7.44772 10.4477 7 11 7H16C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9H11C10.4477 9 10 8.55228 10 8Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8C2 7.44772 2.44772 7 3 7H7C7.55228 7 8 7.44772 8 8C8 8.55228 7.55228 9 7 9H3C2.44772 9 2 8.55228 2 8Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 20C2 19.4477 2.44772 19 3 19H7C7.55228 19 8 19.4477 8 20C8 20.5523 7.55228 21 7 21H3C2.44772 21 2 20.5523 2 20Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C10 19.4477 10.4477 19 11 19H16C16.5523 19 17 19.4477 17 20C17 20.5523 16.5523 21 16 21H11C10.4477 21 10 20.5523 10 20Z"
      fill={color}
    />
  </Svg>
);

export const Classic = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5C2 3.89543 2.89543 3 4 3H6C7.10457 3 8 3.89543 8 5V7C8 8.10457 7.10457 9 6 9H4C2.89543 9 2 8.10457 2 7V5ZM6 5H4V7H6V5Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 17C2 15.8954 2.89543 15 4 15H6C7.10457 15 8 15.8954 8 17V19C8 20.1046 7.10457 21 6 21H4C2.89543 21 2 20.1046 2 19V17ZM6 17H4V19H6V17Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4C10 3.44772 10.4477 3 11 3H20C20.5523 3 21 3.44772 21 4C21 4.55228 20.5523 5 20 5H11C10.4477 5 10 4.55228 10 4Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 16C10 15.4477 10.4477 15 11 15H21C21.5523 15 22 15.4477 22 16C22 16.5523 21.5523 17 21 17H11C10.4477 17 10 16.5523 10 16Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8C10 7.44772 10.4477 7 11 7H16C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9H11C10.4477 9 10 8.55228 10 8Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C10 19.4477 10.4477 19 11 19H16C16.5523 19 17 19.4477 17 20C17 20.5523 16.5523 21 16 21H11C10.4477 21 10 20.5523 10 20Z"
      fill={color}
    />
  </Svg>
);

export const Grid = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5C3 3.89543 3.89543 3 5 3H9C10.1046 3 11 3.89543 11 5V9C11 10.1046 10.1046 11 9 11H5C3.89543 11 3 10.1046 3 9V5ZM5 5V9H9V5H5Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 5C13 3.89543 13.8954 3 15 3H19C20.1046 3 21 3.89543 21 5V9C21 10.1046 20.1046 11 19 11H15C13.8954 11 13 10.1046 13 9V5ZM15 5V9H19V5H15Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 15C3 13.8954 3.89543 13 5 13H9C10.1046 13 11 13.8954 11 15V19C11 20.1046 10.1046 21 9 21H5C3.89543 21 3 20.1046 3 19V15ZM5 15V19H9V15H5Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 15C13 13.8954 13.8954 13 15 13H19C20.1046 13 21 13.8954 21 15V19C21 20.1046 20.1046 21 19 21H15C13.8954 21 13 20.1046 13 19V15ZM15 15V19H19V15H15Z"
      fill={color}
    />
  </Svg>
);

export const CloseActive = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.34285 4.92894C5.95232 4.53842 5.31916 4.53842 4.92864 4.92894C4.53811 5.31946 4.53811 5.95263 4.92864 6.34315L10.5858 12.0003L4.92923 17.6569C4.53871 18.0474 4.5387 18.6806 4.92923 19.0711C5.31975 19.4616 5.95292 19.4616 6.34344 19.0711L12 13.4145L17.6566 19.0711C18.0471 19.4616 18.6802 19.4616 19.0708 19.0711C19.4613 18.6806 19.4613 18.0474 19.0708 17.6569L13.4142 12.0003L19.0714 6.34315C19.4619 5.95263 19.4619 5.31946 19.0714 4.92894C18.6808 4.53842 18.0477 4.53842 17.6572 4.92894L12 10.5861L6.34285 4.92894Z"
      fill={color}
    />
  </Svg>
);

export const UaePass = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 26 26" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.047 23.997a.095.095 0 01-.006-.005l-.021-.012c-.622-.342-1.654-1.351-1.72-1.401l-.002-.002a.677.677 0 00-1.03.277.634.634 0 00.034.546l.008.032c.832 1.095 2.151 1.64 2.151 1.64a.634.634 0 00.816-.346.605.605 0 00-.23-.729z"
      fill="#E82227"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.856 3.668c3.972-2.096 7.946-1.93 11.918 0 .442.215.894.364 1.155-.191.229-.487-.112-.752-.528-.962C17.294 1.449 15.063.88 12.684.886c-2.292-.019-4.431.54-6.432 1.621-.423.229-.822.504-.504 1.036.28.471.7.34 1.108.125zM5.568 21.145c-.37 0-.67.293-.67.654 0 .36.3.654.67.654.37 0 .67-.293.67-.654 0-.361-.3-.654-.67-.654zM14.298 10.147c0-.36-.301-.654-.671-.654-.37 0-.67.294-.67.654 0 .36.3.654.67.654.37 0 .67-.293.67-.654zM21.504 19.652c-.37 0-.67.294-.67.654 0 .361.3.654.67.654.37 0 .67-.293.67-.654 0-.36-.3-.654-.67-.654zM16.459 16.997c-.37 0-.67.294-.67.654 0 .36.3.654.67.654.37 0 .67-.293.67-.654 0-.36-.3-.654-.67-.654z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.459 16.997c-.37 0-.67.294-.67.654 0 .36.3.654.67.654.37 0 .67-.293.67-.654 0-.36-.3-.654-.67-.654zM24.54 12.695c-.37 0-.671.294-.671.654 0 .361.3.655.67.655.37 0 .671-.294.671-.655 0-.36-.3-.654-.67-.654zM24.216 11.207c0-.36-.301-.654-.671-.654-.37 0-.67.293-.67.654 0 .36.3.654.67.654.37 0 .67-.293.67-.654zM4.214 5.545c.37 0 .67-.294.67-.655a.663.663 0 00-.67-.654c-.37 0-.67.294-.67.654 0 .361.3.655.67.655zM1.732 11.294a.671.671 0 00-.582.335l-.002-.001-.008.02a.626.626 0 00-.055.138c-.572 1.48-.607 3.017-.607 3.017 0 .36.3.654.67.654a.667.667 0 00.651-.505H1.8v-.004a.645.645 0 00.019-.145c0-.023-.005-.045-.007-.067.089-1.47.536-2.53.536-2.53a.637.637 0 00.055-.258.663.663 0 00-.67-.654z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.46 7.233c-5.511 2.146-6.933 7.28-5.436 12.3a.671.671 0 001 .44.65.65 0 00.316-.685l-.009-.032c-.132-.474-.259-.95-.355-1.43-1.072-5.328 2.518-9.898 7.786-9.898 4.838 0 8.092 2.948 8.092 7.383 0 1.764-.72 2.644-2 2.617H18.77l-.008.008a.659.659 0 00-.583.646c0 .301.21.552.494.628a.246.246 0 00.034.011h.004c.005.002.011.002.016.003l.052.005c.023.002.046.007.07.007l.026-.002c.135.004.296.002.296.002 2.1-.162 2.855-1.869 2.855-4.334 0-6.146-6.711-9.948-12.566-7.669z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.772 5.48c.028-.005.053-.015.08-.022l-.083.022h.003z"
      fill="#00AEEF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.302 4.715l-.008-.031a.657.657 0 00-.757-.478l-.149.044c-1.076.322-1.968.76-2.884 1.295-1.261.739-2.265 1.572-3.325 2.902-.151.19-.421.44-.6.707a.64.64 0 00-.164.36c-.037.18.006.359.182.527l.02.019c.023.02.049.042.077.063l.01.006c.712.514 1.165-.457 1.483-.858 1.671-2.108 3.286-3.207 5.582-3.8l.047-.013a.638.638 0 00.486-.743zM22.677 8.69a.649.649 0 00-.118-.155c-2.44-3.113-5.702-4.62-9.61-4.7-.174.003-.612 0-.663 0-.37 0-.67.294-.67.655 0 .36.3.654.67.654 3.44-.179 6.905 1.211 9.247 4.213l.026.032a.67.67 0 00.533.263A.661.661 0 0022.76 9a.632.632 0 00-.084-.31zM19.268 15.313a4.632 4.632 0 00-.078-.616c-.315-1.693-1.293-3.224-2.601-4.174.112.09.195.214.23.355l.01.033a.66.66 0 01-.32.697.687.687 0 01-.719-.01c1.074.886 1.98 2.354 2.14 3.833.052.475.101.96.705.94.685-.022.657-.572.633-1.058z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.279 11.692a.69.69 0 00.229-.085.66.66 0 00.32-.696l-.008-.033a.669.669 0 00-.231-.355l-.026-.019a.682.682 0 00-.404-.132.675.675 0 00-.683.666.647.647 0 00.196.463c.04.031.08.063.119.096a.688.688 0 00.488.095z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.883 11.672a8.487 8.487 0 00-.211-.17c.064.064.134.122.211.17z"
      fill="#00AEEF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.481 12.352l-.005-.001c.002 0 .004 0 .006.002l-.001-.001zM16.18 23.064c-.37-.13-.755-.233-1.106-.401-2.833-1.36-4.358-3.587-4.504-6.663-.065-1.375.847-2.34 2.121-2.39l.019-.006a.687.687 0 00.216-.073.643.643 0 00.346-.565.642.642 0 00-.341-.562.67.67 0 00-.3-.082c-.007 0-.014-.003-.022-.003-.015 0-.03.004-.044.005-1.705.107-3.08 1.329-3.303 2.989-.504 3.738 2.745 8.216 6.503 8.966.428.086.816.063.947-.418.116-.422-.147-.661-.532-.797zM16.2 15.11a.645.645 0 00-.037-.147c-.124-.478-.317-1.038-.593-1.417a.655.655 0 00-.07-.091l-.013-.017-.004-.003a.675.675 0 00-.483-.203c-.37 0-.67.294-.67.655 0 .068.013.133.033.195l-.002.003a2.061 2.061 0 00.04.09c.07.16.223.54.404 1.127.01.06.027.119.052.172a.707.707 0 00.98.32.684.684 0 00.363-.685z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.995 20.773l.071.122a1.145 1.145 0 00-.07-.122zM7.819 21.395c.031.068.069.133.113.194l-.072-.126-.041-.069z"
      fill="#E82227"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.191 9.675c-.107 0-.302.07-.302.07-2.766.81-4.633 3.303-4.604 6.326.056 1.891.887 4.19 1.437 5.152a2.884 2.884 0 00.12.21l.04.071a.676.676 0 001.223-.377c0-.103-.026-.199-.07-.285l-.04-.069a9.305 9.305 0 01-1.349-5.185c.07-2.219 1.651-4.014 3.674-4.618a.652.652 0 00.116-.034c.06-.016.237-.127.254-.174a.64.64 0 00.172-.433c0-.36-.3-.654-.67-.654z"
      fill="#00AC75"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.358 20.703a.676.676 0 00-.304-.083l-.114.009c-2.873.244-5.208-1.638-5.457-4.44-.043-.487-.028-1.055-.711-1.005-.63.046-.636.664-.603 1.154.21 3.165 2.937 5.611 6.243 5.646a4.784 4.784 0 00.779-.07.706.706 0 00.163-.063.654.654 0 00.352-.575.654.654 0 00-.348-.573z"
      fill={color}
    />
  </Svg>
);

export const G2P = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 26 26" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.252 3.9c-1.518 0-2.786.332-3.837.962-1.057.632-1.879 1.532-2.47 2.727-.58 1.184-.89 2.627-.89 4.357v.024c0 1.308.178 2.463.517 3.473.34 1.006.82 1.846 1.433 2.536a6.198 6.198 0 002.238 1.567c.88.363 1.895.554 3.057.554 1.079 0 2.018-.155 2.829-.449.822-.302 1.504-.73 2.06-1.28a5.518 5.518 0 001.286-2.012l.002-.004c.306-.8.468-1.722.468-2.78v-1.1h-6.346a.9.9 0 010-1.8h8.145v2.9c0 1.24-.189 2.385-.585 3.421a7.318 7.318 0 01-1.706 2.656c-.753.744-1.66 1.306-2.705 1.69l-.004.001c-1.04.377-2.193.557-3.444.557-1.361 0-2.614-.224-3.745-.69l-.002-.002a7.997 7.997 0 01-2.884-2.022l-.006-.007c-.791-.89-1.387-1.948-1.797-3.16v-.002c-.412-1.225-.61-2.577-.61-4.047v-.024c0-1.942.346-3.669 1.074-5.151l.001-.002c.735-1.487 1.788-2.655 3.159-3.475C8.865 2.494 10.464 2.1 12.252 2.1c1.456 0 2.782.259 3.958.801 1.16.536 2.124 1.286 2.873 2.255a.9.9 0 01-1.423 1.101c-.56-.724-1.289-1.299-2.205-1.721-.9-.416-1.961-.636-3.203-.636z"
      fill={color}
    />
  </Svg>
);

export const Whish = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 26 26" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.726 4.038a1 1 0 011.236.687L7.14 15.85l3.927-10.209a1 1 0 011.867 0l3.926 10.21L20.04 4.724a1 1 0 011.923.55l-4 14a1 1 0 01-1.895.084L12 8.786 7.934 19.359a1 1 0 01-1.895-.084l-4-14a1 1 0 01.687-1.237z"
      fill={color}
    />
  </Svg>
);

export const Twitter = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <G clipPath="url(#clip0_4143_31953)">
      <Path
        d="M13.903 10.655L21.348 2h-1.764l-6.465 7.515L7.955 2H2l7.808 11.364L2 22.439h1.764l6.828-7.935 5.452 7.935H22l-8.098-11.784zm-2.417 2.809l-.791-1.132L4.4 3.328h2.71l5.08 7.267.791 1.131 6.604 9.446h-2.71l-5.389-7.708z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_4143_31953">
        <Path fill={color} transform="translate(2 2)" d="M0 0H20V20.45H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export const Facebook = ({size = 24, color = '#0D0D2B'}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <G clipPath="url(#clip0_2768_81476)">
      <Path
        d="M23.5 12.07C23.5 5.719 18.351.57 12 .57S.5 5.719.5 12.07c0 5.74 4.205 10.497 9.703 11.36v-8.036h-2.92V12.07h2.92V9.536c0-2.882 1.717-4.474 4.344-4.474 1.258 0 2.574.225 2.574.225v2.83h-1.45c-1.429 0-1.874.886-1.874 1.796v2.157h3.19l-.51 3.324h-2.68v8.036c5.498-.863 9.703-5.62 9.703-11.36z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_2768_81476">
        <Path fill={color} d="M0 0H24V24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
