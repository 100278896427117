import {ColorsType, useColors, useStyle} from '../hooks';
import {Icon, IconName} from '../Icon';
import {Pressable, PressableProps} from '../Pressable';
import {SZ} from '../consts';
import {StyleProp, ViewStyle, GestureResponderEvent, ActivityIndicator} from 'react-native';
import {useCallback, useState} from 'react';

export interface IconButtonProps extends PressableProps {
  style?: StyleProp<ViewStyle>;
  name: IconName;
  size?: number;
  containerSize?: number;
  color?: ColorsType;
  loading?: boolean;
  rtlIcon?: boolean;
  onPress: ((event: GestureResponderEvent) => any) | undefined;
  iconStyle?: StyleProp<ViewStyle>;
  loadingColor?: ColorsType;
}

export function IconButton({
  style = {},
  color = 'color',
  name,
  size = 24,
  containerSize = SZ.x12,
  iconStyle = {},
  loading = false,
  rtlIcon = false,
  loadingColor,
  onPress,
  ...props
}: IconButtonProps) {
  const S = useStyle();
  const colors = useColors();
  const [loadingState, toggle] = useState(false);

  const handleOnPress = useCallback(
    async (event: GestureResponderEvent) => {
      if (onPress) {
        const mightBePromise = onPress(event);
        if (mightBePromise && mightBePromise?.then) {
          toggle(true);
          await mightBePromise;
          toggle(false);
        }
      }
    },
    [onPress]
  );

  return (
    <Pressable
      bg="transparent"
      onPress={handleOnPress}
      {...props}
      style={[
        S.align,
        S.justify,
        {
          width: containerSize,
          height: containerSize,
        },
        style,
      ]}>
      {loadingState || loading ? (
        <ActivityIndicator color={colors[loadingColor || color]} />
      ) : (
        <Icon name={name} size={size} color={color} style={iconStyle} rtl={rtlIcon} />
      )}
    </Pressable>
  );
}
