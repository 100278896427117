export function If({
  condition,
  fallback = null,
  children,
}: {
  condition: boolean;
  children: React.ReactNode;
  fallback?: React.ReactElement | null;
}) {
  return condition ? <>{children}</> : fallback;
}
