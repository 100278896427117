import {useColors, ColorsType} from '../hooks/useColors';
import {
  GestureResponderEvent,
  Pressable as DPressable,
  PressableProps as DPressableProps,
} from 'react-native';
import {useCallback} from 'react';

export interface PressableProps extends DPressableProps {
  bg?: ColorsType;
  onPress?: (event: GestureResponderEvent) => void;
}

export function Pressable(props: PressableProps) {
  const {style, bg = 'transparent', ...otherProps} = props;
  const colors = useColors();
  const backgroundColor = colors[bg];

  const getStyles = useCallback(
    (state: any) => {
      const st = typeof style === 'function' ? style(state) : style;
      return [{backgroundColor}, st];
    },
    [backgroundColor, style]
  );

  return <DPressable style={getStyles} {...otherProps} />;
}
