import {UIContext} from '../context';
import {useContext} from 'react';

export const LanguageNames = {
  en: 'en',
  ar: 'ar',
} as const;

export function useAppLang() {
  const {locale} = useContext(UIContext);
  return locale;
}

export function useIsRTL() {
  const lang = useAppLang();
  return lang === 'ar';
}
