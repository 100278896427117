import {IconProps} from '../types';
import {ClipPath, Defs, G, Path, Rect, Svg} from 'react-native-svg';

export const Success = ({size = 141, height}: IconProps) => (
  <Svg width={size} height={height || Number(size) * 1.31} viewBox="0 0 103 135" fill="none">
    <Path
      d="M81.065 107.453c-16.32-6.015-42.69-6.045-58.905-.09-8.145 3-12.135 6.93-12.075 10.875v.27c.075 1.065.45 2.115 1.095 3.15.09.15.195.299.3.449.12.165.255.331.39.495.45.525.96 1.066 1.575 1.591.3.255.63.525.975.78h.015c.48.375 1.005.72 1.575 1.065.015 0 .03.03.06.03 1.74 1.065 3.825 2.054 6.3 2.954 3.135 1.155 6.66 2.1 10.41 2.805 12.06 2.295 26.445 2.295 38.415 0 3.63-.689 7.05-1.605 10.095-2.73 2.43-.884 4.5-1.875 6.18-2.91a27.638 27.638 0 001.545-1.02s.015.001.03-.029c.03-.03.09-.06.105-.09 2.88-2.16 4.29-4.486 4.23-6.841h.015c-.09-3.9-4.2-7.799-12.315-10.784l-.015.03z"
      fill="#ACACAC"
      fillOpacity={0.1}
    />
    <Path
      d="M78.17 52.237L40.355 90.112a.372.372 0 01-.51 0L25.28 75.548c-5.085-5.085-13.335-5.085-18.42 0l-.54.54c-5.085 5.085-5.085 13.335 0 18.42l14.82 14.804 9.75 9.75c5.085 5.085 13.335 5.085 18.42 0l9.75-9.75 38.07-38.13c5.085-5.085 5.07-13.32 0-18.404l-.54-.54c-5.085-5.085-13.335-5.085-18.42 0z"
      fill="#42CE72"
      stroke="#000"
      strokeWidth={1.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M94.97 33.367s-11.7-1.41-16.605 7.26l-3.99-5.205s6.015-8.324 19.17-6.84c0 0-.54 0 1.425 4.77v.016z"
      fill="#CB74AD"
      stroke="#010101"
      strokeWidth={0.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M84.665 4.702s-9.36 3.106-9.99 11.415L69.8 13.613s1.59-8.444 12.075-12.014c0 0-.405.194 2.775 3.104h.015z"
      fill="#00C35D"
      stroke="#010101"
      strokeWidth={0.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M30.455 10.432s-3.975-5.34-9.42-3.974l.585-3.645s5.745-.765 10.245 5.19c0 0-.21-.225-1.395 2.43h-.015z"
      fill="#D78E00"
      stroke="#010101"
      strokeWidth={0.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.4 46.612s10.26-6.645 17.22 2.925l2.325-5.925s-7.635-7.53-20.055-2.895c0 0 1.245.796.51 5.895z"
      fill="#CB74AD"
      stroke="#010101"
      strokeWidth={0.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M28.295 26.317a1.83 1.83 0 100-3.66 1.83 1.83 0 000 3.66z"
      fill="#00C35D"
      stroke="#010101"
      strokeWidth={0.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M63.575 32.377a2.16 2.16 0 100-4.32 2.16 2.16 0 000 4.32z"
      fill="#D78E00"
      stroke="#010101"
      strokeWidth={0.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M52.43 11.047c-1.785-1.845 1.725-6.015 1.725-6.015-1.725.48-3.135 1.936-4.08 3.405-1.17 1.846-.78 3.6.39 5.356.63.945 1.425 1.74 2.04 2.684.3.465.6.96.795 1.485.135.36.24.78.27 1.155v.285c-.27.09-.585.555-.705.57-.465.09-.915.345-1.395.375-.99.06-1.92-.585-2.88-.3-1.725.51-3.075 1.755-4.11 3.18-1.275 1.756-.975 3.075.405 4.71a15.232 15.232 0 003.045 2.76c.915.646 1.95 1.186 2.715 2.025.705.78 1.425 2.475.66 3.465-.75.946-3.27 1.576-4.38 1.8-.645.136-.915.69-1.29 1.185-.09.12-1.365 1.35-.975 1.26 2.445-.495 4.665-1.154 6.48-2.94 1.425-1.38 3.15-3.45 2.895-5.58-.3-2.46-3.045-3.45-4.74-4.845a14.65 14.65 0 01-2.625-2.82c-.405-.57-.465-1.305-.42-1.35.375-.434.855-.63 1.26-.674 2.205-.27 4.11.135 5.91-1.425 1.065-.93 2.16-1.86 2.52-3.21v-.015c.24-.9.045-1.876-.465-2.655-.63-.945-1.8-2.64-3-3.885l-.045.014z"
      fill="#5FAEE1"
      stroke="#010101"
      strokeWidth={0.75}
      strokeMiterlimit={10}
    />
    <Path
      d="M46.835 37.612c-.645.136-1.305 1.08-1.68 1.575-.09.12-.915 1.336-.54 1.26 2.28-.465 5.7-2.1 6.555-4.14-.84.87-3.27 1.096-4.335 1.32v-.015zM53.555 19.418c-.27.09-.525.165-.63.18-.465.09-.945.12-1.41.15-.99.06-1.965.03-2.94.314a4.24 4.24 0 00-.465.165c-.975.616-1.77 1.53-1.89 2.686.405-.136.84-.21 1.245-.256 2.205-.27 4.2 0 5.985-1.575 1.065-.93 2.1-2.13 2.46-3.495-.63 1.29-2.34 1.77-2.34 1.816l-.015.015z"
      fill="#3667B0"
      stroke="#010101"
      strokeWidth={0.75}
      strokeMiterlimit={10}
    />
  </Svg>
);

export const Statement = ({size = 141, height}: IconProps) => (
  <Svg width={size} height={height || Number(size) * 1.63} viewBox="0 0 85 139" fill="none">
    <G clipPath="url(#clip0_891_81555)">
      <Path
        d="M7.596 17.69l6.375 12.195"
        stroke="#D81822"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M55.026 18.695a2.715 2.715 0 100-5.43 2.715 2.715 0 000 5.43z"
        fill="#D78E00"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.581 35.015a3.57 3.57 0 014.2-2.655 3.571 3.571 0 012.76 2.88c.075.435.06.87-.045 1.29a3.566 3.566 0 01-4.185 2.655 3.434 3.434 0 01-1.59-.855 3.544 3.544 0 01-1.14-3.33v.015z"
        fill="#D81822"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M76.701 26.285l-5.4 7.8"
        stroke="#CB74AD"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M67.926 35.615a2.484 2.484 0 012.115 4.38c-.24.18-.525.315-.81.39a2.487 2.487 0 01-3-1.725 2.56 2.56 0 010-1.26c.21-.855.855-1.53 1.695-1.785z"
        fill="#CB74AD"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M55.026 10.715v-9.48"
        stroke="#3C5CA7"
        strokeWidth={1.05}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M37.821 35.96c-2.085-4.05 7.95-6.78 7.755-11.43-.12-2.94-13.845 4.68-12.81 6.51 1.035 1.83 13.8-6.45 11.355-9.78-2.445-3.33-14.52 4.92-14.655 5.925-.105.795.84 2.61 10.605-4.65s-5.595-7.05-11.58.03c-3.525 4.17 8.415-.9 10.965-5.91 2.49-4.89-9.48-.54-13.065 2.52"
        stroke="#3D3D38"
        strokeWidth={1.05}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <Path
        d="M71.481 112.175c-16.32-6.015-42.69-6.045-58.905-.09-8.145 3-12.15 6.93-12.075 10.875v.27c.075 1.065.45 2.115 1.095 3.15.09.15.195.3.3.45.12.165.255.33.39.495.45.525.96 1.065 1.575 1.59.3.255.63.525.975.78h.015c.48.375 1.005.72 1.575 1.065.015 0 .03.03.06.03 1.74 1.065 3.825 2.055 6.3 2.955 3.135 1.155 6.66 2.1 10.41 2.805 12.06 2.295 26.445 2.295 38.415 0 3.63-.69 7.05-1.605 10.095-2.73 2.43-.885 4.5-1.875 6.18-2.91a27.638 27.638 0 001.545-1.02s.015 0 .03-.03c.03-.03.09-.06.105-.09 2.88-2.16 4.29-4.485 4.23-6.84h.015c-.09-3.9-4.2-7.8-12.315-10.785l-.015.03z"
        fill="#ACACAC"
        fillOpacity={0.1}
      />
      <Path
        d="M42.096 104.885c6.06 0 11.175 5.115 11.175 11.175 0 6.06-5.115 11.055-11.175 11.055-6.06 0-11.055-5.115-11.055-11.055 0-5.94 4.98-11.175 11.055-11.175zM33.561 89.99l-1.905-34.41c-.27-4.92 3.645-9.075 8.58-9.075h3.735c4.92 0 8.85 4.125 8.58 9.045l-1.83 34.41c-.24 4.56-4.02 8.145-8.58 8.145-4.56 0-8.325-3.57-8.58-8.115z"
        fill="#D78E00"
        stroke="#010101"
        strokeWidth={1.05}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_891_81555">
        <Path fill="#fff" transform="translate(.5 .5)" d="M0 0H84V138H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export const Failed = ({size = 141, height}: IconProps) => (
  <Svg width={size} height={height || Number(size) * 1.47} viewBox="0 0 85 125" fill="none">
    <G clipPath="url(#clip0_891_82061)">
      <Path
        d="M71.481 98.683c-16.32-6.016-42.69-6.046-58.905-.09-8.145 2.999-12.15 6.944-12.075 10.874v.27c.075 1.065.45 2.115 1.095 3.15.09.15.195.3.3.45.12.165.255.33.39.495.45.525.96 1.065 1.575 1.59.3.255.63.525.975.78h.015c.48.375 1.005.72 1.575 1.065.015 0 .03.03.06.03 1.74 1.065 3.825 2.055 6.3 2.955 3.135 1.155 6.66 2.1 10.41 2.805 12.06 2.295 26.445 2.295 38.415 0 3.63-.69 7.05-1.605 10.095-2.73 2.43-.885 4.5-1.875 6.18-2.91a27.638 27.638 0 001.545-1.02s.015 0 .03-.03c.03-.03.09-.06.105-.09 2.88-2.16 4.29-4.485 4.23-6.84h.015c-.09-3.9-4.2-7.8-12.315-10.784l-.015.03z"
        fill="#ACACAC"
        fillOpacity={0.1}
      />
      <Path
        d="M76.476 42.013l.54.54c4.62 4.62 4.62 12.12 0 16.755l-17.58 17.58 17.565 17.564c4.62 4.62 4.62 12.12 0 16.755l-.54.54c-4.62 4.62-12.12 4.62-16.755 0L42.141 94.183l-17.565 17.564c-4.62 4.62-12.12 4.62-16.755 0l-.54-.54c-4.62-4.62-4.62-12.12 0-16.754l17.565-17.566L7.281 59.323c-4.62-4.62-4.62-12.12 0-16.755l.54-.54c4.62-4.62 12.12-4.62 16.755 0l17.565 17.566 17.58-17.58c4.62-4.62 12.12-4.62 16.755 0z"
        fill="#F2392F"
        stroke="#000"
        strokeWidth={1.05}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M69.096 7.482l-6.36 12.195"
        stroke="#D81822"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M41.031 7.797a2.715 2.715 0 100-5.43 2.715 2.715 0 000 5.43z"
        fill="#3C5CA7"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M63.126 24.808a3.57 3.57 0 00-4.2-2.655 3.571 3.571 0 00-2.76 2.88c-.075.434-.06.87.045 1.29a3.566 3.566 0 004.185 2.654c.6-.134 1.14-.434 1.59-.855.945-.84 1.38-2.1 1.14-3.33v.015z"
        fill="#D81822"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15.201 10.107l5.4 7.8"
        stroke="#D78E00"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M23.976 19.438a2.484 2.484 0 00-2.115 4.38c.24.18.525.314.81.39a2.487 2.487 0 003-1.725 2.56 2.56 0 000-1.26 2.485 2.485 0 00-1.695-1.785z"
        fill="#D78E00"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_891_82061">
        <Path fill="#fff" transform="translate(.5 .5)" d="M0 0H84.001V124.5H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export const Result = ({size = 141, height}: IconProps) => (
  <Svg width={size} height={height || Number(size) * 1.44} viewBox="0 0 89 129" fill="none">
    <G clipPath="url(#clip0_891_82159)">
      <Path
        d="M73.561 101.91c-16.32-6.015-42.69-6.045-58.905-.09-8.145 3-12.135 6.93-12.075 10.875v.27c.075 1.065.45 2.115 1.095 3.15.09.15.195.3.3.45.12.165.255.33.39.495.45.525.96 1.065 1.575 1.59.3.255.63.525.975.78h.015c.48.375 1.005.72 1.575 1.065.015 0 .03.03.06.03 1.74 1.065 3.825 2.055 6.3 2.955 3.135 1.155 6.66 2.1 10.41 2.805 12.06 2.295 26.445 2.295 38.415 0 3.63-.69 7.05-1.605 10.095-2.73 2.43-.885 4.5-1.875 6.18-2.91a27.638 27.638 0 001.545-1.02s.015 0 .03-.03c.03-.03.09-.06.105-.09 2.88-2.16 4.29-4.485 4.23-6.84h.015c-.09-3.9-4.2-7.8-12.315-10.785l-.015.03z"
        fill="#ACACAC"
        fillOpacity={0.1}
      />
      <Path
        d="M78.676 113.295a3.593 3.593 0 01-5.055-.21L50.911 88.77l5.265-4.83 22.71 24.315a3.593 3.593 0 01-.21 5.055v-.015z"
        fill="#F4F4F4"
        stroke="#1D1D1B"
        strokeWidth={1.05}
        strokeMiterlimit={10}
      />
      <Path
        d="M56.199 83.914l-5.272 4.838 4.047 4.41 5.271-4.838-4.046-4.41z"
        fill="#C9C9C9"
        stroke="#1D1D1B"
        strokeWidth={1.05}
        strokeMiterlimit={10}
      />
      <Path
        d="M34.336 95.085c16.386 0 29.67-13.284 29.67-29.67 0-16.386-13.284-29.67-29.67-29.67-16.386 0-29.67 13.284-29.67 29.67 0 16.386 13.284 29.67 29.67 29.67z"
        fill="#E2E2E1"
        stroke="#1D1D1B"
        strokeWidth={1.05}
        strokeMiterlimit={10}
      />
      <Path
        d="M34.336 90.36c13.777 0 24.945-11.168 24.945-24.945 0-13.777-11.168-24.945-24.945-24.945-13.777 0-24.945 11.168-24.945 24.945 0 13.777 11.168 24.945 24.945 24.945z"
        fill="#F4F4F4"
        stroke="#1D1D1B"
        strokeWidth={1.05}
        strokeMiterlimit={10}
      />
      <Path
        d="M29.941 6.78a2.715 2.715 0 100-5.43 2.715 2.715 0 000 5.43z"
        fill="#D78E00"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M88.081 35.31l-12.015 6.675"
        stroke="#D81822"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M73.561 46.485a3.587 3.587 0 00-2.04-4.53 3.603 3.603 0 00-3.9.885c-.3.315-.525.69-.675 1.11a3.568 3.568 0 002.025 4.515c.57.21 1.2.27 1.8.165a3.574 3.574 0 002.79-2.16v.015z"
        fill="#D81822"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M.376 13.14l4.41 8.385"
        stroke="#CB74AD"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7.951 23.445c-1.26-.48-2.67.12-3.195 1.35a2.491 2.491 0 00.555 2.73c.225.21.48.375.765.495 1.245.48 2.655-.12 3.195-1.35.15-.405.21-.825.135-1.26a2.506 2.506 0 00-1.455-1.98v.015z"
        fill="#CB74AD"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M44.236 27.36c2.55-3.765 9.75 3.72 13.755 1.32 2.52-1.515-10.74-9.915-11.85-8.13-1.11 1.785 12.27 9.015 14.025 5.28 1.755-3.75-11.28-10.38-12.225-10.02-.75.285-1.89 1.995 9.165 7.08s3.51-8.295-5.58-10.155c-5.34-1.095 4.815 6.945 10.44 6.795 5.49-.15-4.065-8.565-8.475-10.26"
        stroke="#3D3D38"
        strokeWidth={1.05}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_891_82159">
        <Path fill="#fff" d="M0 0H88.5V129H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export const Exclamation = ({size = 141, height}: IconProps) => (
  <Svg width={size} height={height || Number(size) * 1.63} viewBox="0 0 85 139" fill="none">
    <G clipPath="url(#clip0_4504_7356)">
      <Path
        d="M7.596 17.69l6.375 12.195"
        stroke="#D81822"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M55.026 18.695a2.715 2.715 0 100-5.43 2.715 2.715 0 000 5.43z"
        fill="#D78E00"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.581 35.015a3.57 3.57 0 014.2-2.655 3.571 3.571 0 012.76 2.88c.075.435.06.87-.045 1.29a3.566 3.566 0 01-4.185 2.655 3.434 3.434 0 01-1.59-.855 3.544 3.544 0 01-1.14-3.33v.015z"
        fill="#D81822"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M76.701 26.285l-5.4 7.8"
        stroke="#CB74AD"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M67.926 35.615a2.484 2.484 0 012.115 4.38c-.24.18-.525.315-.81.39a2.487 2.487 0 01-3-1.725 2.56 2.56 0 010-1.26c.21-.855.855-1.53 1.695-1.785z"
        fill="#CB74AD"
        stroke="#010101"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M55.026 10.715v-9.48"
        stroke="#3C5CA7"
        strokeWidth={1.05}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M37.821 35.96c-2.085-4.05 7.95-6.78 7.755-11.43-.12-2.94-13.845 4.68-12.81 6.51 1.035 1.83 13.8-6.45 11.355-9.78-2.445-3.33-14.52 4.92-14.655 5.925-.105.795.84 2.61 10.605-4.65s-5.595-7.05-11.58.03c-3.525 4.17 8.415-.9 10.965-5.91 2.49-4.89-9.48-.54-13.065 2.52"
        stroke="#3D3D38"
        strokeWidth={1.05}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <Path
        d="M71.481 112.175c-16.32-6.015-42.69-6.045-58.905-.09-8.145 3-12.15 6.93-12.075 10.875v.27c.075 1.065.45 2.115 1.095 3.15.09.15.195.3.3.45.12.165.255.33.39.495.45.525.96 1.065 1.575 1.59.3.255.63.525.975.78h.015c.48.375 1.005.72 1.575 1.065.015 0 .03.03.06.03 1.74 1.065 3.825 2.055 6.3 2.955 3.135 1.155 6.66 2.1 10.41 2.805 12.06 2.295 26.445 2.295 38.415 0 3.63-.69 7.05-1.605 10.095-2.73 2.43-.885 4.5-1.875 6.18-2.91a27.638 27.638 0 001.545-1.02s.015 0 .03-.03c.03-.03.09-.06.105-.09 2.88-2.16 4.29-4.485 4.23-6.84h.015c-.09-3.9-4.2-7.8-12.315-10.785l-.015.03z"
        fill="#ACACAC"
        fillOpacity={0.1}
      />
      <Rect x={31.5} y={75.5} width={21} height={52} rx={10.5} fill="#D78E00" stroke="#010101" />
      <Path
        d="M42.216 68.735c-6.06 0-11.175-5.115-11.175-11.175 0-6.06 5.115-11.055 11.175-11.055 6.06 0 11.055 5.115 11.055 11.055 0 5.94-4.98 11.175-11.055 11.175z"
        fill="#D78E00"
        stroke="#010101"
        strokeWidth={1.05}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_4504_7356">
        <Path fill="#fff" transform="translate(.5 .5)" d="M0 0H84V138H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
