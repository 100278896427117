import {IconProps} from '../types';
import {Circle, Path, Rect, Svg} from 'react-native-svg';

export const Area = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      opacity="0.3"
      d="M7.99994 10L1.99994 17.5V20C1.99994 21.1046 2.89537 22 3.99994 22H19.4999C20.6045 22 21.4999 21.1046 21.4999 20V3L13.4999 14L7.99994 10Z"
      fill={color}
      fillOpacity="0.7"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5633 2.17375C22.0197 2.48487 22.1374 3.107 21.8262 3.56331L14.9069 13.7116C14.2713 14.6439 12.9907 14.8661 12.0782 14.2024L8.21738 11.3946L3.30641 18.0913C2.97981 18.5367 2.35401 18.633 1.90864 18.3064C1.46328 17.9798 1.367 17.354 1.6936 16.9086L6.60457 10.2118C7.25595 9.32359 8.50291 9.12923 9.39372 9.77709L13.2545 12.5849L20.1738 2.43664C20.4849 1.98033 21.107 1.86263 21.5633 2.17375Z"
      fill={color}
    />
  </Svg>
);

export const Bars = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 20C5.44772 20 5 19.5523 5 19V9H4C3.44772 9 3 8.55228 3 8C3 7.44772 3.44772 7 4 7L5 7L5 5C5 4.44771 5.44772 4 6 4C6.55229 4 7 4.44771 7 5L7 16H10C10.5523 16 11 16.4477 11 17C11 17.5523 10.5523 18 10 18H7V19C7 19.5523 6.55228 20 6 20Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 21C14.4477 21 14 20.5523 14 20V16H13C12.4477 16 12 15.5523 12 15C12 14.4477 12.4477 14 13 14H14L14 4C14 3.44772 14.4477 3 15 3C15.5523 3 16 3.44772 16 4L16 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H16V20C16 20.5523 15.5523 21 15 21Z"
      fill={color}
    />
  </Svg>
);

export const Baseline = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0669 4.00225C12.4804 4.02998 12.8339 4.30985 12.9558 4.70593L16.1633 15.1304L19.0837 8.45124C19.305 7.94521 19.8946 7.71436 20.4006 7.93562C20.9066 8.15688 21.1375 8.74646 20.9162 9.25249L16.9162 18.4006C16.7487 18.7837 16.3606 19.0222 15.9432 18.9984C15.5257 18.9746 15.1672 18.6937 15.0442 18.2941L11.8128 7.79194L10.9079 9.75241C10.7641 10.0641 10.4704 10.2802 10.13 10.3249C9.78965 10.3695 9.45017 10.2364 9.2308 9.97237L8.28852 8.83815L4.92348 16.9392C4.71162 17.4492 4.12641 17.6909 3.61638 17.4791C3.10635 17.2672 2.86463 16.682 3.07649 16.172L7.07649 6.54233C7.21082 6.21894 7.50452 5.98938 7.85078 5.93713C8.19704 5.88488 8.54541 6.01757 8.76918 6.28692L9.75627 7.47509L11.092 4.58095C11.2657 4.2047 11.6534 3.97453 12.0669 4.00225Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12.6666C1 12.1143 1.44772 11.6666 2 11.6666L22 11.6666C22.5523 11.6666 23 12.1143 23 12.6666C23 13.2189 22.5523 13.6666 22 13.6666L2 13.6666C1.44772 13.6666 1 13.2189 1 12.6666Z"
      fill={color}
    />
  </Svg>
);

export const Candles = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Rect x="7" y="3" width="2" height="18" rx="1" fill={color} />
    <Rect x="5" y="7" width="6" height="10" rx="2" fill={color} />
    <Rect x="15" y="5" width="2" height="12" rx="1" fill={color} />
    <Rect x="13" y="8" width="6" height="6" rx="2" fill={color} />
  </Svg>
);

export const ColoredBar = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 21L4 17.375H6V18H7C7.55228 18 8 18.4477 8 19C8 19.5523 7.55228 20 7 20H6V21H4ZM4 16.125L4 10.875H6L6 16.125H4ZM4 7L4 6H6V9.625H4L4 9L3 9C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7H4Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 21L18 17.375H20V18H21C21.5523 18 22 18.4477 22 19C22 19.5523 21.5523 20 21 20H20V21H18ZM18 16.125L18 10.875H20L20 16.125H18ZM18 7L18 6H20V9.625H18L18 9L17 9C16.4477 9 16 8.55228 16 8C16 7.44772 16.4477 7 17 7H18Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 20C12.4477 20 12 19.5523 12 19L12 8L9 8C8.44772 8 8 7.55229 8 7C8 6.44772 8.44772 6 9 6L12 6L12 3C12 2.44772 12.4477 2 13 2C13.5523 2 14 2.44772 14 3L14 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H14V19C14 19.5523 13.5523 20 13 20Z"
      fill={color}
    />
  </Svg>
);

export const ColoredHLCBar = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 21L3 17.375L5 17.375L5 21L3 21ZM3 16.125L3 10.875L5 10.875L5 16.125L3 16.125ZM3 9.625L3 6L5 6L5 9.625L3 9.625Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 18C4 17.4477 4.44772 17 5 17H6C6.55228 17 7 17.4477 7 18C7 18.5523 6.55228 19 6 19H5C4.44772 19 4 18.5523 4 18Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 21L16 17.375L18 17.375L18 21L16 21ZM16 16.125L16 10.875L18 10.875L18 16.125L16 16.125ZM16 9.625L16 6L18 6L18 9.625L16 9.625Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 18C17 17.4477 17.4477 17 18 17H19C19.5523 17 20 17.4477 20 18C20 18.5523 19.5523 19 19 19H18C17.4477 19 17 18.5523 17 18Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 20C10.4477 20 10 19.5523 10 19L10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3L12 19C12 19.5523 11.5523 20 11 20Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9C14 9.55228 13.5523 10 13 10L12 10C11.4477 10 11 9.55228 11 9C11 8.44772 11.4477 8 12 8L13 8C13.5523 8 14 8.44772 14 9Z"
      fill={color}
    />
  </Svg>
);

export const PointAndFigure = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 13.2929C2.68342 12.9024 3.31658 12.9024 3.70711 13.2929L5 14.5858L6.29289 13.2929C6.68342 12.9024 7.31658 12.9024 7.70711 13.2929C8.09763 13.6834 8.09763 14.3166 7.70711 14.7071L6.41421 16L7.70711 17.2929C8.09763 17.6834 8.09763 18.3166 7.70711 18.7071C7.31658 19.0976 6.68342 19.0976 6.29289 18.7071L5 17.4142L3.70711 18.7071C3.31658 19.0976 2.68342 19.0976 2.29289 18.7071C1.90237 18.3166 1.90237 17.6834 2.29289 17.2929L3.58579 16L2.29289 14.7071C1.90237 14.3166 1.90237 13.6834 2.29289 13.2929Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 5.29289C2.68342 4.90237 3.31658 4.90237 3.70711 5.29289L5 6.58579L6.29289 5.29289C6.68342 4.90237 7.31658 4.90237 7.70711 5.29289C8.09763 5.68342 8.09763 6.31658 7.70711 6.70711L6.41421 8L7.70711 9.29289C8.09763 9.68342 8.09763 10.3166 7.70711 10.7071C7.31658 11.0976 6.68342 11.0976 6.29289 10.7071L5 9.41421L3.70711 10.7071C3.31658 11.0976 2.68342 11.0976 2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L3.58579 8L2.29289 6.70711C1.90237 6.31658 1.90237 5.68342 2.29289 5.29289Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2929 5.29289C16.6834 4.90237 17.3166 4.90237 17.7071 5.29289L19 6.58579L20.2929 5.29289C20.6834 4.90237 21.3166 4.90237 21.7071 5.29289C22.0976 5.68342 22.0976 6.31658 21.7071 6.70711L20.4142 8L21.7071 9.29289C22.0976 9.68342 22.0976 10.3166 21.7071 10.7071C21.3166 11.0976 20.6834 11.0976 20.2929 10.7071L19 9.41421L17.7071 10.7071C17.3166 11.0976 16.6834 11.0976 16.2929 10.7071C15.9024 10.3166 15.9024 9.68342 16.2929 9.29289L17.5858 8L16.2929 6.70711C15.9024 6.31658 15.9024 5.68342 16.2929 5.29289Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7ZM9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15ZM9 16C9 14.3431 10.3431 13 12 13C13.6569 13 15 14.3431 15 16C15 17.6569 13.6569 19 12 19C10.3431 19 9 17.6569 9 16Z"
      fill={color}
    />
  </Svg>
);

export const HeikinAshi = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Rect x="7.5" y="4.5" width={size} height={size} rx="0.5" fill={color} stroke={color} />
    <Rect
      x="6"
      y="9"
      width={size}
      height={size}
      rx="1"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
    <Rect x="15.5" y="6.5" width={size} height={size} rx="0.5" fill={color} stroke={color} />
    <Rect
      x="14"
      y="9"
      width={size}
      height={size}
      rx="1"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
  </Svg>
);

export const Histogram = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      d="M3 16C3 15.4477 3.44772 15 4 15V15C4.55228 15 5 15.4477 5 16V20C5 20.5523 4.55228 21 4 21V21C3.44772 21 3 20.5523 3 20V16Z"
      fill={color}
    />
    <Path
      d="M8 12C8 11.4477 8.44772 11 9 11V11C9.55228 11 10 11.4477 10 12V20C10 20.5523 9.55228 21 9 21V21C8.44772 21 8 20.5523 8 20V12Z"
      fill={color}
    />
    <Path
      d="M13 8C13 7.44772 13.4477 7 14 7V7C14.5523 7 15 7.44772 15 8V20C15 20.5523 14.5523 21 14 21V21C13.4477 21 13 20.5523 13 20V8Z"
      fill={color}
    />
    <Path
      d="M19 4C19 3.44772 19.4477 3 20 3V3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21V21C19.4477 21 19 20.5523 19 20V4Z"
      fill={color}
    />
  </Svg>
);

export const HollowCandles = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Rect x="7.5" y="16.5" width={size} height={size} rx="0.5" stroke={color} />
    <Rect x="7.5" y="3.5" width={size} height={size} rx="0.5" stroke={color} />
    <Rect x="6" y="8" width={size} height={size} rx="1" stroke={color} strokeWidth="2" />
    <Rect x="15.5" y="13.5" width={size} height={size} rx="0.5" stroke={color} />
    <Rect x="15.5" y="5.5" width={size} height={size} rx="0.5" stroke={color} />
    <Rect x="14" y="9" width={size} height={size} rx="1" stroke={color} strokeWidth="2" />
  </Svg>
);

export const Kagi = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7071 20.7071C14.5196 20.8946 14.2652 21 14 21L9 21C8.44771 21 8 20.5523 8 20L8 9L6 9L6 17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17L4 8C4 7.73478 4.10536 7.48043 4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7L9 7C9.55228 7 10 7.44771 10 8L10 19L13 19L13 4C13 3.73478 13.1054 3.48043 13.2929 3.29289C13.4804 3.10536 13.7348 3 14 3L19 3C19.5523 3 20 3.44772 20 4L20 9C20 9.55229 19.5523 10 19 10C18.4477 10 18 9.55229 18 9L18 5L15 5L15 20C15 20.2652 14.8946 20.5196 14.7071 20.7071Z"
      fill={color}
    />
  </Svg>
);

export const Line = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5633 4.17375C22.0197 4.48487 22.1374 5.107 21.8262 5.56331L14.9069 15.7116C14.2713 16.6439 12.9907 16.8661 12.0782 16.2024L8.21738 13.3946L3.30641 20.0913C2.97981 20.5367 2.35401 20.633 1.90864 20.3064C1.46328 19.9798 1.367 19.354 1.6936 18.9086L6.60457 12.2118C7.25595 11.3236 8.50291 11.1292 9.39372 11.7771L13.2545 14.5849L20.1738 4.43664C20.4849 3.98033 21.107 3.86263 21.5633 4.17375Z"
      fill={color}
    />
  </Svg>
);

export const LineBreak = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 5H11V9H13V5ZM11 3C9.89543 3 9 3.89543 9 5V9H7C5.89543 9 5 9.89543 5 11V14C5 15.1046 5.89543 16 7 16H9C10.1046 16 11 15.1046 11 14V11H13V19C13 20.1046 13.8954 21 15 21H17C18.1046 21 19 20.1046 19 19V11C19 9.89543 18.1046 9 17 9H15V5C15 3.89543 14.1046 3 13 3H11ZM17 11H15V19H17V11ZM7 11H9V14H7L7 11Z"
      fill={color}
    />
  </Svg>
);

export const Range = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.75 16.5C15.75 16.0858 16.0858 15.75 16.5 15.75H18.5C19.7426 15.75 20.75 16.7574 20.75 18C20.75 18.9322 20.1831 19.732 19.3752 20.0734L20.6708 22.6646C20.8561 23.0351 20.7059 23.4856 20.3354 23.6708C19.9649 23.8561 19.5144 23.7059 19.3292 23.3354L17.7865 20.25H17.25V23C17.25 23.4142 16.9142 23.75 16.5 23.75C16.0858 23.75 15.75 23.4142 15.75 23V16.5ZM17.25 18.75H18.5C18.9142 18.75 19.25 18.4142 19.25 18C19.25 17.5858 18.9142 17.25 18.5 17.25H17.25V18.75Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 13C16.4477 13 16 12.5523 16 12V8H15C14.4477 8 14 7.55228 14 7C14 6.44772 14.4477 6 15 6H16L16 2C16 1.44771 16.4477 1 17 1C17.5523 1 18 1.44771 18 2L18 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H17Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 19C5.44772 19 5 18.5523 5 18V14H4C3.44772 14 3 13.5523 3 13C3 12.4477 3.44772 12 4 12H5L5 4C5 3.44771 5.44771 3 6 3H9C9.55228 3 10 3.44772 10 4C10 4.55228 9.55228 5 9 5H7L7 18C7 18.5523 6.55228 19 6 19Z"
      fill={color}
    />
  </Svg>
);

export const Renko = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 4H15V8H17V4ZM15 2C13.8954 2 13 2.89543 13 4V8H11C9.89543 8 9 8.89543 9 10V14H7C5.89543 14 5 14.8954 5 16V20C5 21.1046 5.89543 22 7 22H9C10.1046 22 11 21.1046 11 20V16H13C14.1046 16 15 15.1046 15 14V10H17C18.1046 10 19 9.10457 19 8V4C19 2.89543 18.1046 2 17 2H15ZM13 10H11V14H13V10ZM7 16H9V20H7L7 16Z"
      fill={color}
    />
  </Svg>
);

export const Scatterplot = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Circle cx="15" cy="7" r="2" fill={color} />
    <Circle cx="21" cy="16" r="2" fill={color} />
    <Circle cx="12" cy="14" r="2" fill={color} />
    <Circle cx="7" cy="9" r="2" fill={color} />
    <Circle cx="3" cy="16" r="2" fill={color} />
  </Svg>
);

export const Step = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 22.0002C4.44772 22.0002 4 21.5525 4 21.0002L4 7.00004C4 6.44775 4.44772 6.00004 5 6.00004L8 6.00004C8.55229 6.00004 9 6.44775 9 7.00004L9 9.00024L10 9.00024C10.5523 9.00024 11 9.44796 11 10.0002L11 15.5002L11.5 15.5002L11.5 12.0002C11.5 11.735 11.6054 11.4807 11.7929 11.2931C11.9804 11.1056 12.2348 11.0002 12.5 11.0002L14 11.0002L14 3.00003C14 2.73482 14.1054 2.48046 14.2929 2.29292C14.4804 2.10539 14.7348 2.00003 15 2.00003L18 2.00003C18.5523 2.00003 19 2.44775 19 3.00003L19 21C19 21.5523 18.5523 22 18 22C17.4477 22 17 21.5523 17 21L17 4.00003L16 4.00003L16 12.0002C16 12.2655 15.8946 12.5198 15.7071 12.7074C15.5196 12.8949 15.2652 13.0002 15 13.0002L13.5 13.0002L13.5 16.5002C13.5 17.0525 13.0523 17.5002 12.5 17.5002L10 17.5002C9.73479 17.5002 9.48043 17.3949 9.2929 17.2073C9.10536 17.0198 9 16.7655 9 16.5002L9 11.0002L8 11.0002C7.44772 11.0002 7 10.5525 7 10.0002L7 8.00004L6 8.00004L6 21.0002C6 21.5525 5.55229 22.0002 5 22.0002Z"
      fill={color}
    />
  </Svg>
);

export const VolumeCandle = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Rect x="15" y="14" width={size} height={size} rx="1" fill={color} />
    <Rect x="15" y="3" width={size} height={size} rx="1" fill={color} />
    <Rect x="13" y="7" width={size} height={size} rx="1" stroke={color} strokeWidth="2" />
    <Rect x="6" y="16" width={size} height={size} rx="1" fill={color} />
    <Rect x="6" y="8" width={size} height={size} rx="1" fill={color} />
    <Rect x="5" y="12" width={size} height={size} rx="1" stroke={color} strokeWidth="2" />
  </Svg>
);

export const VortexLine = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5634 4.17375C22.0197 4.48487 22.1374 5.107 21.8263 5.56331L14.907 15.7116C14.2714 16.6439 12.9908 16.8661 12.0782 16.2024L8.21744 13.3946L3.30647 20.0913C2.97987 20.5367 2.35407 20.633 1.9087 20.3064C1.46334 19.9798 1.36706 19.354 1.69366 18.9086L6.60463 12.2118C7.25601 11.3236 8.50297 11.1292 9.39378 11.7771L13.2546 14.5849L20.1738 4.43664C20.485 3.98033 21.1071 3.86263 21.5634 4.17375Z"
      fill={color}
    />
    <Path
      d="M22.4215 5.01208C22.4215 5.84051 21.7499 6.51208 20.9215 6.51208C20.0931 6.51208 19.4215 5.84051 19.4215 5.01208C19.4215 4.18366 20.0931 3.51208 20.9215 3.51208C21.7499 3.51208 22.4215 4.18366 22.4215 5.01208Z"
      fill={color}
      stroke={color}
    />
    <Path
      d="M14.9999 15.5C14.9999 16.3284 14.3284 17 13.4999 17C12.6715 17 11.9999 16.3284 11.9999 15.5C11.9999 14.6716 12.6715 14 13.4999 14C14.3284 14 14.9999 14.6716 14.9999 15.5Z"
      fill={color}
      stroke={color}
    />
    <Circle cx="8" cy="12" r="1.5" fill={color} stroke={color} />
    <Path
      d="M3.50006 19.937C3.50006 20.7654 2.82849 21.437 2.00006 21.437C1.17163 21.437 0.500061 20.7654 0.500061 19.937C0.500061 19.1086 1.17163 18.437 2.00006 18.437C2.82849 18.437 3.50006 19.1086 3.50006 19.937Z"
      fill={color}
      stroke={color}
    />
  </Svg>
);

export const Drawing = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 25 24" fill={color}>
    <Path
      d="M18.4644 4.34939L9.55827 13.2555L8.92573 15.5748L11.2451 14.9423L20.1512 6.03617C20.6106 5.57679 20.6106 4.80877 20.1512 4.34939C19.6918 3.89001 18.9238 3.89001 18.4644 4.34939ZM21.5654 2.93518C22.7931 4.16285 22.7811 6.23467 21.5654 7.45039L12.4715 16.5443C12.3484 16.6673 12.1954 16.7561 12.0275 16.8019L7.76363 17.9648C7.0309 18.1646 6.33591 17.4697 6.53575 16.7369L7.69862 12.4731C7.74441 12.3052 7.83322 12.1521 7.95628 12.0291L17.0502 2.93518C18.2746 1.71077 20.3295 1.69932 21.5654 2.93518Z"
      fill={color}
    />
    <Path
      d="M2.5 21C2.5 20.4477 2.94772 20 3.5 20H12.5C13.0523 20 13.5 20.4477 13.5 21C13.5 21.5523 13.0523 22 12.5 22H3.5C2.94772 22 2.5 21.5523 2.5 21Z"
      fill={color}
    />
  </Svg>
);

export const Studies = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 21.318 25.52" fill={color} stroke={color}>
    <Path d="M 11.504 9.348 L 8.594 9.348 L 7.803 17.248 A 16.076 16.076 0 0 1 6.987 21.34 A 6.753 6.753 0 0 1 5.205 23.869 A 5.86 5.86 0 0 1 1.152 25.52 Q 0.703 25.52 0 25.276 L 1.299 24.084 A 4.855 4.855 0 0 0 2.803 24.358 A 3.787 3.787 0 0 0 6.133 19.992 L 6.387 17.57 L 7.207 9.348 L 5.439 9.348 L 6.553 8.215 L 7.324 8.215 L 7.383 7.649 A 9.926 9.926 0 0 1 9.463 2.019 Q 11.191 0.002 13.926 0.002 A 7.83 7.83 0 0 1 15.088 0.1 L 13.721 1.447 A 3.964 3.964 0 0 0 12.285 1.164 A 2.584 2.584 0 0 0 9.893 2.326 Q 9.18 3.488 8.857 6.779 L 8.711 8.215 L 12.666 8.215 L 11.504 9.348 Z M 15.576 13.352 L 15.771 13.029 L 15.469 12.229 Q 15.137 11.379 15.107 11.311 L 14.639 10.266 A 1.523 1.523 0 0 0 13.682 9.143 A 1.43 1.43 0 0 0 12.588 10.442 Q 12.295 10.334 12.295 10.129 A 2.227 2.227 0 0 1 12.998 8.742 A 1.889 1.889 0 0 1 14.258 7.961 Q 15.352 7.961 16.523 11.106 L 16.719 11.623 L 16.943 11.262 A 5.9 5.9 0 0 1 20.402 7.962 A 1.246 1.246 0 0 1 20.449 7.961 A 2.252 2.252 0 0 1 21.318 8.176 L 20.137 9.416 Q 19.902 9.348 19.805 9.348 Q 18.73 9.348 17.246 11.819 L 16.963 12.287 L 17.188 12.893 A 6.953 6.953 0 0 0 19.126 16.398 A 0.459 0.459 0 0 0 19.307 16.438 A 1.327 1.327 0 0 0 20.469 15.344 A 0.474 0.474 0 0 1 20.742 15.705 A 1.823 1.823 0 0 1 19.961 16.877 Q 19.18 17.561 18.613 17.561 A 4.056 4.056 0 0 1 16.299 14.367 L 16.025 13.645 L 15.742 14.143 Q 13.75 17.561 11.934 17.561 A 1.947 1.947 0 0 1 10.771 17.219 L 11.904 16.106 A 0.937 0.937 0 0 0 12.588 16.399 A 3.734 3.734 0 0 0 15.068 14.172 L 15.576 13.352 Z" />
  </Svg>
);

export const ChartGrid = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={color}>
    <Path
      d="M2 3C2 2.44772 2.44772 2 3 2H10C10.5523 2 11 2.44772 11 3V21C11 21.5523 10.5523 22 10 22H3C2.44772 22 2 21.5523 2 21V3ZM4 4V20H9V4H4Z"
      fill={color}
    />
    <Path
      d="M13 3C13 2.44772 13.4477 2 14 2H21C21.5523 2 22 2.44772 22 3V12C22 12.5523 21.5523 13 21 13H14C13.4477 13 13 12.5523 13 12V3ZM15 4V11H20V4H15Z"
      fill={color}
    />
    <Path
      d="M13 16C13 15.4477 13.4477 15 14 15H21C21.5523 15 22 15.4477 22 16V21C22 21.5523 21.5523 22 21 22H14C13.4477 22 13 21.5523 13 21V16ZM15 17V20H20V17H15Z"
      fill={color}
    />
  </Svg>
);

export const Crosshair = ({size = 24, color}: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={color}>
    <Path
      d="M12 2C12.5523 2 13 2.44772 13 3V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V3C11 2.44772 11.4477 2 12 2Z"
      fill={color}
    />
    <Path
      d="M2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12Z"
      fill={color}
    />
  </Svg>
);
